<!--
	Tab-group is a container which allows dynamic content to be defined
		and consumed directly within the html without data structures/js.

	This component works in tandem with the <tab-item> component.
-->
<template>
	<div class="tab-wrapper">

		<v-tabs v-model="activeTab">
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index" 
				:href="tab.href"
				>
					<button>
						{{tab.title}}
					</button>
			</v-tab>
		</v-tabs>

		<div class="tab-content">
			<slot>
				<!--
					build a list using <tab-item> components
				-->
			</slot>
		</div>

	</div>
</template>

<script>
	export default {
		name: "tabGroup",
		props: {
			value: {
				required: false
			},
		},
		created() {
			this.tabs = this.$children;
		},
		mounted() {
			this.tabs = this.tabs.splice(1);
		},
		data() {
			return {
				tabs: [],
				activeTab: ''
			};
		},
		methods: {
			selectTab(titleID) {
				this.tabs.forEach(tab => {
					tab.isActive = ( tab.titleID === titleID );
				})
			},
		},
		watch: {
			// communicate changes via v-bind
			activeTab: function() {
				this.selectTab(this.activeTab);
				this.$emit('input', this.activeTab);
			},
			// react to upstream changes via v-bind
			value: function() {
				if (this.value !== this.activeTab) {
					this.activeTab = this.value;
				}
			}
		}
	};
</script>
