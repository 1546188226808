<template>
  <v-text-field
    class="search-bar"
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    hide-details
    single-line
    append-icon="mdi-magnify"
    :placeholder="searchPlaceholder"
    @input="(v) => $emit('input', v)"
  />
</template>

<script>
/**
 * @fileoverview Common component for a search bar
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "search-bar",
  inheritAttrs: false,
  props: {
    /**
     * Search value
     */
    value: {
      type: String,
    },
    /**
     * Placeholder string for the search bar
     * Default: "search by key data..."
     */
    searchPlaceholder: {
      type: String,
      default: "search by key data...",
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  min-width: 15em;
  max-width: 35%;

  &::v-deep .v-input__control {
    .v-text-field__slot {
      border: 1px solid rgba(150, 150, 150, 0.3);
      border-right: none;
      border-radius: 0.25em 0 0 0.25em;
    }

    .v-input__slot::before,
    .v-input__slot::after {
      content: unset;
    }

    .v-input__append-inner {
      padding: 0.35em;
      background-color: $qvo-color-brand-blue-wash;
      border-radius: 0 0.25em 0.25em 0;
      max-height: 34px; /* determined by vuetify's input height + border */
    }
  }
}
</style>