import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import CustomIcons from '@/components/custom-icons';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		disable: true
		/*
		primary: '#03A4AB',
		secondary: '#161a3c',
		accent: '#ffc95e',
		error: '#AE2727',
		info: '#2196F3',
		success: '#56BB81',
		warning: '#E9A43C',
		anchor: '#03A4AB',
		*/
	},
	icons: {
		values: {
			home: {
				component: CustomIcons,
				props: {
					name: 'home',
				},
			},
			pdf: {
				component: CustomIcons,
				props: {
					name: 'pdf',
				},
			},
			queue: {
				component: CustomIcons,
				props: {
					name: 'queue',
				},
			},
		},
	},
});
