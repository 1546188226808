import router from '@/router'

export const state = {
    activeTab: '/',
    leftPanel: {
        expanded: true,
        transitioning: false,
    },
    navList: [{
        label: "Home",
        href: "/",
        hideCloseButton: true,
        appliedMerchantCredits: {
            show: false,
            disputeId: null,
        },
        recoveryDetails: {
            show: false,
            disputeId: null,
        },
    }],
};

// Don't display the nav bar for these paths
const navHiddenPaths = [
    '/embedded/*',
];

/*
    Actions hook up to the interface, but they do not touch the store data directly.
    They can be rather complicated and will generally lean on one or more mutations.
    Actions are also the communication layer with external sources (API).

    Actions come with access to four contexts ({ state, getters, commit, dispatch })
        state = direct data access... avoid use
        getters = indirect data access... this is good
        commit = call a mutation
        dispatch = for us, dispatch will almost certainly be an axios api call
*/
export const actions = {
    addNavItem({ commit, getters }, item) {
        const navList = getters.getNavList;

        const { hideCloseButton, href, label, ownerName } = item;

        // check if navItem is already in navList, only refresh the openedAt time
        const existingItem = navList.findIndex(nav => nav.href === href);
        if (existingItem >= 0) {
            navList[existingItem].openedAt = Date.now();
        } else {

            // push item onto nav list
            const navItem = {
                hideCloseButton,
                href,
                label,
                ownerName,
                hover: false,
                openedAt: Date.now(),
                appliedMerchantCredits: {
                    show: false,
                    disputeId: null,
                },
                recoveryDetails: {
                    show: false,
                    disputeId: null,
                },
            };

            navList.push(navItem);
        }

        commit("SET_NAV_LIST", navList);
        router.push(item.href);
    },

    /**
     * Remove a navigation item from the nav list
     * @param {*} contexts - commit, getters 
     * @param {String} href - href of nav item to be removed 
     */
    removeNavItem({ commit, getters, rootState }, href) {
        const navList = getters.getNavList;
        const updatedNavList = navList.filter(item => item.href !== href);

        // when closing the active tab, need to adjust the route
        if (rootState.route.path === href) router.replace('/');

        commit("SET_NAV_LIST", updatedNavList);
    },
    async navPanelTransitioning({ commit }) {
        commit("SET_NAV_PANEL_TRANSITIONING", true);
        await new Promise(resolve => setTimeout(resolve, 2100));
        commit("SET_NAV_PANEL_TRANSITIONING", false);
    },
    openTab({ dispatch }, { path }) {

        const pathparts = path.split("/");

        let navItemProps = {
            href: path,
            label: "",
            hideCloseButton: false,
        }

        switch (pathparts[1]) {
            case 'case-view':
                navItemProps.label = pathparts[2];
                break;
            case 'create-claim':
                navItemProps.label = 'Create Claim';
                break;
            case 'task':
                navItemProps.label = decodeURI(pathparts[2]);
                break;
            case 'tasks':
                navItemProps.label = 'Tasks';
                break;
        }

        dispatch("addNavItem", navItemProps);
    },
    async setActiveTab({ commit, dispatch, getters }, path) {
        const navList = getters.getNavList;

        const tabExists = !!navList.find(nav => nav.href === path);

        if (!tabExists) {
            await dispatch('openTab', { path });
        }

        commit('SET_TAB', path);
    },
    async showAppliedMerchantCredits({ commit, getters }, { DisputeId }) {
        const navList = getters.getNavList;
        const activeTab = getters.getActiveTab;

        const updatedNavList = navList.map(navItem => {
            if (navItem.href === activeTab) {
                return {
                    ...navItem,
                    appliedMerchantCredits: {
                        show: !!DisputeId,
                        disputeId: DisputeId
                    }
                };
            }
            return navItem;
        });

        commit('SET_NAV_LIST', updatedNavList);
    },
    async showRecoveryDetails({ commit, getters }, { DisputeId }) {
        const navList = getters.getNavList;
        const activeTab = getters.getActiveTab;

        const updatedNavList = navList.map(navItem => {
            if (navItem.href === activeTab) {
                return {
                    ...navItem,
                    recoveryDetails: {
                        show: !!DisputeId,
                        disputeId: DisputeId
                    }
                };
            }
            return navItem;
        });

        commit('SET_NAV_LIST', updatedNavList);
    },
    /**
     * Toggle the side navigation state
     * @param {*} contexts - commit, getters 
     */
    toggleNav({ commit, dispatch }) {
        dispatch('navPanelTransitioning');
        commit("TOGGLE_NAV");
    },
};

/*
    Mutations are methods that act on the state data directly.
    They should be incredibly basic and perform a single task.

    Mutations always take in the current 'state' and a payload.
*/
export const mutations = {
    /**
     * Set navigation list
     * @param {*} state - current state
     * @param {Array} navList - list of nav objects 
     */
    SET_NAV_LIST(state, navList) {
        state.navList = navList;
    },
    SET_NAV_PANEL_TRANSITIONING(state, value) {
        state.leftPanel.transitioning = !!value;
    },
    SET_TAB(state, path) {
        state.activeTab = path;
    },
    /**
     * Toggle the side navigation state
     * @param {*} state - current state
     */
    TOGGLE_NAV(state) {
        state.leftPanel.expanded = !state.leftPanel.expanded;
    },
};

/*
    Getters are non-cached access points to the state data.
    They allow us to interrogate the data and pull back dynamic subsets.
*/

export const getters = {
    getActiveTab: (state, getters) => {
        const activeTab = state.activeTab;
        const navList = getters.getNavList;

        const tab = navList.find(nav => nav.href === activeTab);

        return tab ? tab.href : '/';
    },
    getActiveTabDetails: (state, getters) => {
        const activeTab = state.activeTab;
        const navList = getters.getNavList;

        return navList.find(nav => nav.href === activeTab);
    },
    getAppliedMerchantCreditsView: (state, getters) => getters.getActiveTabDetails && getters.getActiveTabDetails.appliedMerchantCredits,
    getDisputeDetailsIsShowing: (state, getters) => getters.getAppliedMerchantCreditsView.show || getters.getRecoveryDetailsView.show,
    getEnvironment: () => {
        return process.env.VUE_APP_ENV || 'production'
    },
    getIsEmbedded: (state, getters, rootState) => rootState.route.path.includes('/embedded/'),
    /**
     * gets the nav expanded state
     * @param {*} state - current state
     * @returns Bool of nav expansion state
     */
    getNavExpanded: state => state.leftPanel.expanded,
    /**
     * get the navigation list
     * @param {*} state - current state
     * @returns Array of navigation items
     */
    getNavList: state => { return state.navList },
    /**
     * gets the transitioning state of the left panel
     * @param {*} state - current state
     * @returns Bool of transition state
     */
    getNavPanelTransitioning: state => state.leftPanel.transitioning,
    /**
     * gets the width of the side nav
     * @param {*} state - current state
     * @returns Number in pixels of width
     */
    /**
     * gets the hidden state for the side nav
     * @param {*} state - current state
     * @returns Bool of nav hidden state
     */
    getNavHidden: (state, getters, rootState) => {
        // allow using basic wildcards in the URL paths
        const regexPatterns = navHiddenPaths.map(pattern =>
            new RegExp('^' + pattern.replace(/\*/g, '.*') + '$'));

        return regexPatterns.some(regex => regex.test(rootState.route.path))
    },
    getRecoveryDetailsView: (state, getters) => getters.getActiveTabDetails && getters.getActiveTabDetails.recoveryDetails,
    getSideNavWidth: (state, getters) => {
        if (getters.getNavHidden) return 0;
        return getters.getNavExpanded ? 256 : 85;
    },
    /**
     * gets the width of all open panels
     * @param {*} state - current state
     * @returns Number in pixels of width
     */
    // Since only one panel currently, just returning the nav width, can add other panels here later
    getTotalPanelWidth: (state, getters) => getters.getSideNavWidth,
};