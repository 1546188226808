
/**
 * QFDAuthService.js contains a central service for connecting with the QFD Auth API.
 * It depends on axios and axios-retry.
 *
 *
 * @author Dan Kenemer <dan.kenemer@quavo.com>
 */
import * as retry from 'axios-retry';
import * as axios from 'axios';
import store from '@/store/store';


const authClient = axios.create({
    baseURL: `${process.env.VUE_APP_COGNITO_AUTH}/oauth2`,
    withCredentials: false, // This is the default
    timeout: 15000
});

authClient.interceptors.request.use((config) => {
	config.headers = {
		Accept: 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded',
		'Authorization': `Basic ${process.env.VUE_APP_AUTH_BASIC_AUTH}`,
	};
    return config;
});


const apiClient = axios.create({
    baseURL: process.env.VUE_APP_QFD_AUTH_API,
    withCredentials: false, // This is the default
    timeout: 15000,
    transitional: {
        clarifyTimeoutError: true
    }
});

apiClient.interceptors.request.use((config) => {
    config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': store.getters.getAuthAccessToken
    };
    return config;
});

retry(apiClient, {
    retries: 3,
    retryDelay: retry.exponentialDelay
});


export default {
    name: 'call',
    /**
     * Call the QFD API
     * @param {String} url - the URL of the specific endpoint to call
     * @param {Object} params
     * @param {String} params.method - HTTP method to use for call
     * @returns a promise with the API payload
     */
    async call(url, { method = 'post', ...otherParams } = {}) {
        // check if auth token expired
        if (!store.getters.getAuthAccessToken || store.getters.getAuthAccessTokenExpiration - Date.now() < 0) {
            console.error("No valid token found! Refreshing token");
            await store.dispatch('getAuthAccessToken');
        }
        const id = Math.floor(Math.random() * 10000);
        store.dispatch("addPendingCall", { id, url });
        return apiClient.request({
            method,
            url,
            data: { ...otherParams }
        }).then((response) => {
            // Global handling for after API call success

            return response;
        }).catch(error => {
            // Global error handling done here

            // API timeout
            if (error.code === 'ETIMEDOUT') {
                store.dispatch("notifications/addNotification", {
                    status: 'error',
                    message: 'An error has occurred. Please try your action again.',
                })
            }
            throw (error);
        }).finally(() => {
            // Global handling for after API call completion
            store.dispatch("removePendingCall", id);
        });
    },

    async getQFDAuthToken(token) {
        const response = await this.call('', { "Token": token });
		return response.data;
	},

    async getAuthAccessToken() {
		return authClient.post('/token','grant_type=client_credentials&scope=https://auth-api.quantumdisputes.com/request.create');
	},
};