<!--
	HOW TO:  Checkout the store/modules/notification.js file for implementation details.

	NOTE: For unique cases, simply use a v-alert component within your view.
		Do NOT use the notification center when your alert:
		- must display in context on the page
		- includes HTML like media and actions
		- cannot be dismissed / is a persistent element on the page
-->

<template>
	<div id="notification-alert">
		<v-alert
			@input="dismissNotification(notification)"
			:class="notification.status"
			close-icon="mdi-close"
			border="left"
			:icon="getIcon(notification.status)"
			colored-border
			:data-auto-dismiss="notification.autoDismiss"
			:style="'animation-delay:' + notification.dismissTimer + 'ms'"
			dismissible
		>{{ notification.message }}</v-alert>
	</div>
</template>

<script>
	export default {
		name: "notificationCenter",
		props: {
			notification: {
				required: true,
			},
		},
		data() {
			return {
				alert: false,
				timeout: null,
			};
		},
		mounted() {
			if (this.notification.autoDismiss) {
				this.timeout = setTimeout(
					() =>
						this.$store.dispatch(
							"notifications/deleteNotification",
							this.notification
						),
					this.notification.dismissTimer * 1000
				);
			}
		},
		methods: {
			getIcon(status) {
				let icon =
					status == "success"
						? "mdi-check-circle"
						: status == "warning"
						? "mdi-alert-rhombus"
						: status == "error"
						? "mdi-alert-octagon"
						: "mdi-information-outline";
				return icon;
			},
			dismissNotification(notification) {
				this.$store.dispatch(
					"notifications/deleteNotification",
					notification
				);
			},
		},
	};
</script>

<!-- styles defined in _notification_center -->