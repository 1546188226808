import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import QFD from './QFD.vue';
import router from './router';
import store from './store/store';
import titlebar from '@/components/titlebar.vue';
import dynamicNavbar from '@/components/dynamic-navbar.vue';
import togglebutton from '@/qux-common/components/togglebutton.vue';
import infoDialogBox from '@/qux-common/components/info-dialog-box.vue';
import modalDialog from '@/qux-common/components/modal-dialog.vue';
import tabGroup from '@/qux-common/components/tab-group.vue';
import tabItem from '@/qux-common/components/tab-item.vue';
import dataTable from '@/qux-common/components/data-table.vue';
import backNavBtn from '@/qux-common/components/back-nav-btn.vue';
import labeledIcon from '@/qux-common/components/labeled-icon.vue';
import vuetify from './plugins/vuetify';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import draggable from 'vuedraggable';
import VueApexCharts from 'vue-apexcharts';
import notificationCenter from '@/qux-common/components/notificationCenter.vue';
import AsyncComputed from 'vue-async-computed';

Vue.config.productionTip = false;

// enable lodash from anywhere in the app with this._ or this.lodash
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueApexCharts);
Vue.use(AsyncComputed);

sync(store, router);

/*
	GLOBAL COMPONENTS
	no need to import per view - always available
*/

// third-party
Vue.component('draggable', draggable);
Vue.component('apexChart', VueApexCharts);

// QVO
Vue.component('titlebar', titlebar);
Vue.component('dynamic-navbar', dynamicNavbar);
Vue.component('togglebutton', togglebutton);
Vue.component('infoDialogBox', infoDialogBox);
Vue.component('modal-dialog', modalDialog);
Vue.component('tab-group', tabGroup);
Vue.component('tab-item', tabItem);
Vue.component('dataTable', dataTable);
Vue.component('back-nav-btn', backNavBtn);
Vue.component('notificationCenter', notificationCenter);
Vue.component('labeledIcon', labeledIcon);


const app = new Vue({
	router,
	store,
	vuetify,
	render: function (h) { return h(QFD); }
}).$mount('#qvo-app');

app.name = 'Interaction';
