
/**
 * QFDAPIService.js contains a central service for connecting with the QFD API.
 * It depends on axios and axios-retry.
 *
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
import * as retry from 'axios-retry';
import * as axios from 'axios';
import { v4 as uuid } from 'uuid';
import store from '@/store/store';

const apiAuthClient = axios.create({
    baseURL: `${process.env.VUE_APP_API}/oauth2/v1`,
    withCredentials: false, // This is the default
    timeout: 15000
});

apiAuthClient.interceptors.request.use((config) => {
	config.headers = {
		Accept: 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded',
		'Authorization': `Basic ${process.env.VUE_APP_QFD_BASIC_AUTH}`,
	};
    return config;
});

const headerConfigs = (config) => { 
    const auth = store.getters.getLoggedInUserIdToken || store.getters.getQFDAuthIdToken;
    config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': auth
    };
    return config;
}

const apiClient = axios.create({
    baseURL: `${process.env.VUE_APP_API}/QFDAPI/v1/`,
    withCredentials: false, // This is the default
    timeout: 15000,
    transitional: {
        clarifyTimeoutError: true
    }
});

apiClient.interceptors.request.use(headerConfigs);

retry(apiClient, {
    retries: 3,
    retryDelay: retry.exponentialDelay
});


export default {
    name: 'call',
    /**
     * Call the QFD API
     * @param {String} url - the URL of the specific endpoint to call
     * @param {Object} params
     * @param {String} params.method - HTTP method to use for call
     * @returns a promise with the API payload
     */
    async call(url, { method = 'post', ...otherParams } = {}) {
        const id = Math.floor(Math.random() * 10000);
        store.dispatch("addPendingCall", { id, url });
        let theClient;
        if (store.getters.getActiveTenant && store.getters.getActiveTenant.engineURL && store.getters.getActiveTenant.engineURL !== process.env.VUE_APP_API) {
            theClient = axios.create({
                baseURL: store.getters.getActiveTenant.engineURL + '/QFDAPI/v1/',
                withCredentials: false, // This is the default
                timeout: 15000,
            });
            theClient.interceptors.request.use(headerConfigs);
        } else {
            theClient = apiClient;
        }
        return theClient.request({
            method,
            url,
            data: { CorrelationId: uuid(), ...otherParams }
        }).then((response) => {
            // Global handling for after API call success

            return response;
        }).catch(error => {
            // Global error handling done here

            // API timeout
            if (error.code === 'ETIMEDOUT') {
                store.dispatch("notifications/addNotification", {
                    status: 'error',
                    message: 'An error has occurred. Please try your action again.',
                })
            }
            throw (error);
        }).finally(() => {
            // Global handling for after API call completion
            store.dispatch("removePendingCall", id);
        });
    },
};