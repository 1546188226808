export const state = {
    pendingCalls: [], // array of API calls that are in progress
};

/*
    Actions hook up to the interface, but they do not touch the store data directly.
    They can be rather complicated and will generally lean on one or more mutations.
    Actions are also the communication layer with external sources (API).

    Actions come with access to four contexts ({ state, getters, commit, dispatch })
        state = direct data access... avoid use
        getters = indirect data access... this is good
        commit = call a mutation
        dispatch = for us, dispatch will almost certainly be an axios api call
*/
export const actions = {
    /**
     * Add an API call to the pending calls list
     * @param {*} contexts - commit, getters 
     * @param {Object} callInfo - API call info
     */
    addPendingCall({ commit, getters }, callInfo) {
        const pendingCalls = getters.getFullPendingCalls;
        pendingCalls.push(callInfo);

        commit("SET_PENDING_CALLS", pendingCalls);
    },

    /**
     * Remove an API call to the pending calls list
     * @param {*} contexts - commit, getters 
     * @param {Number} id - id of call to be removed
     */
    removePendingCall({ commit, getters }, id) {
        const pendingCalls = getters.getFullPendingCalls;

        const updatedPendingCalls = pendingCalls.filter(call => call.id !== id);

        commit("SET_PENDING_CALLS", updatedPendingCalls);
    },
};

/*
    Mutations are methods that act on the state data directly.
    They should be incredibly basic and perform a single task.

    Mutations always take in the current 'state' and a payload.
*/
export const mutations = {
    /**
     * Set pending calls
     * @param {*} state - current state
     * @param {Array} pendingCalls - pending calls
     */
    SET_PENDING_CALLS(state, pendingCalls) {
        state.pendingCalls = pendingCalls;
    },
};

/*
    Getters are non-cached access points to the state data.
    They allow us to interrogate the data and pull back dynamic subsets.
*/

export const getters = {
    /**
     * get full data back about pending calls
     * @param {*} state - current state
     * @returns Array of pending calls
     */
    getFullPendingCalls: state => state.pendingCalls,
    /**
     * gets a list simple list of currently pending API call urls
     * @param {*} state - current state
     * @returns Array of pending calls
     */
    getPendingCallList: state => [
        // grab just the url from the object, and then filter out duplicates
        ... new Set(state.pendingCalls.map(call => call.url))
    ]

};