<template>
	<v-dialog
		content-class="modal-dialog"
		v-model="showDialog"
		persistent
		attach="#qvo-app"
		:disabled="isDisabled"
		:activator="activator"
	>
		<template v-slot:activator="{ on, attrs }" v-if="useActivator">
			<div class="modal-dialog-activator" v-bind="attrs" v-on="on">
				<slot name="activator">
					<!--
						NOTE:
							Any aditional methods defined within this slot will be ignored!
							This slot is for display purposes only.
					-->
					<v-btn text>{{title}}</v-btn>
				</slot>
			</div>
		</template>
		<div class="modal-dialog-title">
			<h2>{{title}}</h2>
			<v-btn icon @click.stop="closeDiscard" v-show="!forceShowDialog">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</div>
		<div class="modal-dialog-content" :class="{'keep-actions-visible': this.keepActionsVisible}">
			<div class="modal-dialog-content-body">
				<slot name="content">Failed to load dialog contents</slot>
			</div>
			<slot name="actions">
				<div class="action-menu">
					<div class="execute-and-close" v-if="showExecuteAction">
						<v-btn class="primary" @click.stop="closeExecute">
							{{ executeText }}
						</v-btn>
					</div>
					<div class="discard-and-close" v-if="showCancelAction">
						<v-btn text @click.stop="closeDiscard">
							{{ cancelText }}
						</v-btn>
					</div>
				</div>
			</slot>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		name: "modalDialog",
		props: {
			title: String,
			forceShowDialog: {
				/*
					Use with caution. This will prevent the user from closing the dialog.
					Your execute and cancel actions must trigger a page redraw or navigation.
				*/
				type: Boolean,
				default: false
			},
			triggerCounter: {
				/*
					Optionally pass in an externally incremented value.
					A positive change in the value will trigger the dialog
					without having to tie together highly-specific props.
				*/
				type: Number,
				default: 0
			},
			executeText: {
				type: String,
				default: "Okay"
			},
			cancelText: {
				type: String,
				default: "Cancel"
			},
			executeAction: {
				type: Function,
				default: null
			},
			showExecuteAction: {
				type: Boolean,
				default: true
			},
			cancelAction: {
				type: Function,
				default: null
			},
			showCancelAction: {
				type: Boolean,
				default: true
			},
			keepActionsVisible: {
				type: Boolean,
				default: false
			},
			useActivator: {
				type: Boolean,
				default: true
			},
			activator: {
				type: [ String, Object ],
				required: false,
			},
			isDisabled: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				shouldShowDialog: false,
				manualTriggerCount: 0,
			};
		},
		computed: {
			showDialog: {
				get() {
					let show = this.shouldShowDialog ? true : this.forceShowDialog;
					return show;
				},
				set(value) {
					this.shouldShowDialog = value;
				}
			}
		},
		watch: {
			triggerCounter() {
				let newVal = this.triggerCounter;
				if (newVal > 0 && newVal > this.manualTriggerCount) {
					this.manualTriggerCount = newVal;
					this.triggerDialog();
				}
			}
		},
		methods: {
			closeDialog() {
				this.showDialog = false;
			},
			closeExecute() {
				if (this.executeAction) {
					this.executeAction();
				}
				this.closeDialog();
			},
			closeDiscard() {
				if (this.cancelAction) {
					this.cancelAction();
				}
				this.closeDialog();
			},
			triggerDialog() {
				this.showDialog = true;
			}
		}
	};
</script>

<style lang="scss" scoped>
.modal-dialog.v-dialog {
	position: relative;
	z-index: 900;
	display: flex;
	flex-direction: column;
	background-color: #FFF;
	width: min-content;
	min-width: clamp(300px, 650px, 80%);
	max-width: calc(100vw - 4em);
	height: min-content;
	max-height: calc(100vh - 4em);
	box-shadow: $qvo-shadow-far;
	overflow: hidden;

	.modal-dialog-activator {
		width: min-content;
	}

	.modal-dialog-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5em 1em;
		font-weight: normal;
		color: #FFF;
		background-color: $qvo-color-brand-blue;

		h2,h3 {
			padding: 0;
			margin: 0;
			font-family: inherit;
			font-weight: inherit;
		}

		.v-icon {
			color: #FFF;
		}
	}

	.modal-dialog-content {
		background: #fff;
		font-size: 1em;
		padding: 2em;
		max-height: 100%;
		max-width: 100%;
		overflow-y: auto;
	}

	.action-menu {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		padding: 2em 0;
		overflow: hidden;
	}

	.modal-dialog-content.keep-actions-visible {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.modal-dialog-content-body {
			overflow-y: auto;
			overflow-x: hidden;
		}
		.action-menu {
			border-top: $qvo-border-default;
		}
	}
}

#qvo-app .v-overlay {
	// This does not override the dark overlay, but instead sits below and softens it
	opacity: 0.75;
	background-color: #fff;
	border-color: #fff;
}
</style>