/**
 * @fileoverview Define all data and data-interactions for customers
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

 import CustomerService from '@/services/CustomerService';
 
 export const state = {
     customer: {}, // customer data

 };
 
 /*
     Actions hook up to the interface, but they do not touch the store data directly.
     They can be rather complicated and will generally lean on one or more mutations.
     Actions are also the communication layer with external sources (API).
 
     Actions come with access to four contexts ({ state, getters, commit, dispatch })
         state = direct data access... avoid use
         getters = indirect data access... this is good
         commit = call a mutation
         dispatch = for us, dispatch will almost certainly be an axios api call
 */
 export const actions = {

     /**
      * Retrieve customer info
      * @param {*} contexts -  dispatch
      * @param {String} CustomerId - cvustomer id 
      */
     async retrieveCustomer({ commit, dispatch }, CustomerId) {
         await CustomerService.retrieveCustomer(CustomerId, dispatch).then((response) => {
             commit('STORE_CUSTOMER', response.data);
         });
     },
 };
 
 /*
     Mutations are methods that act on the state data directly.
     They should be incredibly basic and perform a single task.
 
     Mutations always take in the current 'state' and a payload.
 */
 export const mutations = {
     /**
      * Set the customer data
      * @param {*} state - current state
      * @param {Object} customer - customer data
      */
      STORE_CUSTOMER(state, customer) {
         state.customer = customer;
     },
 };
 
 /*
     Getters are non-cached access points to the state data.
     They allow us to interrogate the data and pull back dynamic subsets.
 */
 
 export const getters = {
     /**
      * get customer data
      * @param {*} state - current state
      * @returns Object of customer data
      */
     getCustomer: state => { return state.customer },
 };
 