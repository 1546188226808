<template>
  <div class="notifications">
    <div
      class="notification"
      v-for="notification in notificationList"
      :key="notification.id"
    >
      <notification :notification="notification" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from './notification';

export default {
  name: "Notifications",
  components: {
    notification,
  },
  computed: {
    ...mapGetters({
      notificationList: "notifications/getNotificationList",
    }),
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: fixed;
  top: 2em;
  right: 2em;
  z-index: 999;
}
</style>