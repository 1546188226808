/*
	Define all data and data-interactions for Notifications.

	Notifications provide feedback on system events and user actions.
	As a general rule, they are short-lived, client-side instances.
*/
export const namespaced = true;

export const state = {
	notificationList: [],
};

/*
	Actions hook up to the interface, but they do not touch the store data directly.
	They can be rather complicated and will generally lean on one or more mutations.
	Actions are also the communication layer with external sources (API).

	Actions come with access to four contexts ({ state, getters, commit, dispatch })
		state = direct data access... avoid use
		getters = indirect data access... this is good
		commit = call a mutation
		dispatch = for us, dispatch will almost certainly be an axios api call
*/
export const actions = {

	addNotification({ commit }, { status = 'info', title, message, autoDismiss = false, dismissTimer = 5 }) {
		let newNotification = {
			id: Date.now(),
			status,
			title,
			message,
			autoDismiss,
			dismissTimer,
		};

		commit('ADD_NOTIFICATION', newNotification);
	},
	deleteNotification({ commit }, notificationToRemove) {
		commit('DELETE_NOTIFICATION', notificationToRemove);
	}
	//	clearNotificationList({ commit }) {
	// clear out the list for a fresh page view, including non-auto dismissable items
	//	},
};

/*
	Mutations are methods that act on the state data directly.
	They should be incredibly basic and perform a single task.

	Mutations always take in the current 'state' and a payload.
*/
export const mutations = {
	ADD_NOTIFICATION(state, notification) {
		if (state.notificationList.filter(iter => iter.message === notification.message && iter.status === notification.status).length <= 0) {
			/* notificationList is not already displaying the message */
			state.notificationList.push(notification);
		}
	},
	DELETE_NOTIFICATION(state, notificationToRemove) {
		state.notificationList = state.notificationList.filter(
			notification => notification.id !== notificationToRemove.id);
	}
};

export const getters = {
	getNotificationList: state => state.notificationList,
};
