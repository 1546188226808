<template>
  <v-dialog v-model="dialog" class="info-dialog-box">
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        v-on:click.prevent
        class="more-info-button"
      >
        <v-icon v-if="showIcon" class="more-info-icon">{{
          activatorIcon
        }}</v-icon>
        {{ activatorText }}
      </button>
    </template>
    <v-card :class="'info-dialog-box-content ' + containerClass">
      <v-card-title>
        <h4>{{ title }}</h4>
      </v-card-title>
      <slot>
        <!-- content -->
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      default: "Information",
      required: false,
    },
    containerClass: {
      default: "",
      required: false,
    },
    activatorText: {
      default: "learn more",
      required: false,
    },
    activatorIcon: {
      default: "mdi-information-outline",
      required: false,
    },
    showIcon: {
      default: true,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-dialog.info-dialog-box {
  width: auto;
  max-width: clamp(250px, 50em, 100%);
}

.info-dialog-box-content {
  font-size: 1.6rem;
  display: inline-block;
  width: max-content;
  padding: 2em;
  margin: 0 auto;

  .v-card__title {
    padding: 0;
    word-break: normal;
  }
}

.more-info-icon {
  font-size: 1.25em;
  margin-right: 0.25em;
}

.more-info-button {
  .theme--light.v-icon {
    color: $qvo-font-color-link;
  }
}
</style>