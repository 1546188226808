// NOTE:  Any custom icons also need to be defined in src/plugins/vuetify.js

<template>
  <component :is="iconComponent" />
</template>
  
<script>
/**
 * @fileoverview Custom Icons
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

const icons = {
  home: () => import("./icons/home"),
  pdf: () => import("./icons/pdf"),
  queue: () => import("./icons/queue"),
};

export default {
  name: "CustomIcons",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconComponent() {
      return icons[this.name];
    },
  },
};
</script>
  
<style lang="scss" scoped>
</style>