<template>
	<qvoDynamicNavbar :navList="navList" :homePage="homePage" :selectTab="selectTab" @closeTab="closeTab"/>
</template>

<script>
	/**
	 * @fileoverview Dynamic navigation component specific to QFD interaction/intake.
	 * Relies on the qux-common library and vuex.
	 *
	 * @author Sophia Frankel <sophia.frankel@quavo.com>
	 */
	import qvoDynamicNavbar from '@/qux-common/components/dynamic-navbar.vue';
	import { mapState } from 'vuex';

	export default {
		name: 'navbar',
        data() {
            return {
				/**
				 * Home page object
				 */
				homePage: {
					linkTo: {name: "Dashboard"},
					label: 'Dashboard'
				}
            };
        },
		components: {
			qvoDynamicNavbar
		},
		computed: {
			...mapState({
				navList: state => state.claim.navList.sort((a, b) => b.openedAt - a.openedAt)
			})
		},
		methods: {
			/**
			 * Select a claim
			 * @param {Object} claim - selected claim
			 */
			selectTab(claim) {
				this.$store.dispatch("setSelectedClaim", claim.label);
			},
			/**
			 * Remove a tab for the nav bar
			 * @param {Object} claim - claim tab to be closed
			 */
			closeTab(claim) {
				this.$store.dispatch("removeNavItem", claim.label);

				// return to dashboard when closing current tab
				/*
				if (this.$route.params.id === claim.label) {
					this.$router.push({
						name: "Dashboard"
					});
				}*/
			}
		}
	}
</script>