<template>
  <v-app>
    <template v-if="authed || allowUnauth">
      <notifications />
      <router-view />
      <token-manager v-if="!allowUnauth" />
      <v-overlay :dark="false" v-if="authed && !hasAccessToApplication">
        <v-card>
          <h6>Unauthorized</h6>
          <p class="subtitle-1">You are not authorized for this application.</p>
        </v-card>
      </v-overlay>
    </template>
  </v-app>
</template>

<script>
/**
 * @fileoverview QFD.vue is the main view of the qfd interaction app.
 * It depends on vuex for mapping state variables.
 *
 * @author Sophia Frankel <sophia.frankel@quavo.com>
 */
import { mapState } from "vuex";
import notifications from "@/components/notifications";
import tokenManager from "@/components/token-manager";

export default {
  components: {
    notifications,
    tokenManager,
  },
  methods: {
    retrieveConfiguration() {
      if (this.authed) {
        this.$store.dispatch("retrieveConfiguration");
      }
    },
  },
  computed: {
    ...mapState({
      loggedInUserId: (state) => state.account.loggedInUserId,
      loggedInUser: (state) => state.account.loggedInUser,
    }),
    authed() {
      return !!this.loggedInUserId;
    },
    allowUnauth() {
      /*
					Pages that require authorization will redirect to login.
					Otherwise, the content can be viewed directly.
						Such expections must be explicitly defined here.
					This flag does NOT enable API calls / grant data access.
			*/
      //TODO: remove this once alternative auth is setup for embedded pages
      if (this.$route.path.startsWith("/embedded")) {
        return true;
      }
      return false;
    },
    hasAccessToApplication() {
      return (
        !!this.loggedInUser.accessRoles &&
        this.loggedInUser.accessRoles
          .split(",")
          .some((e) => e.startsWith("QFD"))
      );
    },
  },
  watch: {
    authed() {
      this.retrieveConfiguration();
    },
  },
  mounted() {
    this.retrieveConfiguration();
  },
};
</script>

<style lang="scss">
.qvo-color-grey-600 {
  color: $qvo-color-grey-600;
}
</style>
<style lang="scss" scoped>
.v-overlay {
  z-index: 200 !important;
  &::v-deep {
    .v-overlay__scrim {
      background-color: #000 !important;
      opacity: 0.25 !important;
    }
  }
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  width: 400px;
  padding: $qvo-spacing-16 $qvo-spacing-16 $qvo-spacing-16 $qvo-spacing-24;
  box-shadow: $qvo-shadow-2;
  border-radius: 8px;
  h6 {
    margin-bottom: $qvo-spacing-24;
  }
  .subtitle-1 {
    color: $qvo-color-grey-600;
  }
}
</style>