import store from '@/store/store.js';
import API from '@/services/QFDAPIService';

export default {
    name: 'TaskService',
    action({ Action, Detail, TaskList }) {
        return API.call('/task/action', { Action, Detail, TaskList }).catch(error => {
            var message = "";
            var defaultMessage = error.message + '. Please refresh the page or try again later.';
            if (error.response) {
                if (error.response.data.StatusList) {
                    message = "Error submitting action. ";
                    error.response.data.StatusList.forEach(status => message += `${status.Message} `);
                }
            }
            store.dispatch("notifications/addNotification", {
                status: 'error',
                message: message || defaultMessage,
            });
            throw error;
        })
    },
    detailsList({ClaimId, TaskLabel}) {
        return API.call('/task/details/list', {ClaimId, TaskLabel}).catch(error => {
            var message = "";
            var defaultMessage = error.message + '. Please refresh the page or try again later.';
            if (error.response) {
                if (error.response.data.StatusList) {
                    message = "Error retrieving task details list. ";
                    error.response.data.StatusList.forEach(status => message += `${status.Message} `);
                }
            }
            store.dispatch("notifications/addNotification", {
                status: 'error',
                message: message || defaultMessage,
            });
        })
    },
    retrieveList({
        ClientId,
        PageNum,
        PageSize,
        SortBy,
        SortType,
        TaskLabel,
    }) {
        return API.call('/task/list', {
            ClientId,
            PageNum,
            PageSize,
            SortBy,
            SortType,
            TaskLabel,
        }).catch(error => {
            var message = "";
            var defaultMessage = error.message + '. Please refresh the page or try again later.';
            if (error.response) {
                if (error.response.data.StatusList) {
                    message = "Error retrieving task list. ";
                    error.response.data.StatusList.forEach(status => message += `${status.Message} `);
                }
            }
            store.dispatch("notifications/addNotification", {
                status: 'error',
                message: message || defaultMessage,
            });
        })
    },
    retrieveClaimTaskList({ ClaimId }) {
        return API.call('/task/list', { ClaimId }).catch(error => {
            var message = "";
            var defaultMessage = error.message + '. Please refresh the page or try again later.';
            if (error.response) {
                if (error.response.data.StatusList) {
                    message = "Error retrieving claim task list. ";
                    error.response.data.StatusList.forEach(status => message += `${status.Message} `);
                }
            }
            store.dispatch("notifications/addNotification", {
                status: 'error',
                message: message || defaultMessage,
            });
        });

    },
    retrieveSummary({ ClientId }) {
        return API.call('/task/summary', { ClientId }).catch(error => {
            var message = "";
            var defaultMessage = error.message + '. Please refresh the page or try again later.';
            if (error.response) {
                if (error.response.data.StatusList) {
                    message = "Error retrieving task summary. ";
                    error.response.data.StatusList.forEach(status => message += `${status.Message} `);
                }
            }
            store.dispatch("notifications/addNotification", {
                status: 'error',
                message: message || defaultMessage,
            });
        })
    },
};