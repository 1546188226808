<template>
  <v-alert
    :class="[type, inline && 'inline']"
    v-bind="$attrs"
    v-on="$listeners"
    :type="type"
    :icon="!inline && getIcon"
    close-icon="mdi-close"
    @input="close"
    transition="scale-transition"
    origin="middle center"
  >
    <v-icon class="icon" v-if="inline">{{ getIcon }}</v-icon>
    <p class="subtitle-1 title" v-if="title">{{ title }}</p>
    <p class="body-1 message" v-if="message">{{ message }}</p>
    <p class="body-1 link" v-if="linkTo">
      <a :href="linkTo" class="link">{{ linkTitle || linkTo }}</a>
    </p>
    <p class="body-2 timestamp" v-if="timestamp">{{ timestamp }}</p>
    <Button text class="action-button" v-if="action" @click="action">{{
      actionTitle || "Click"
    }}</Button>
  </v-alert>
</template>

<script>
/**
 * @fileoverview Common component alerts
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import Button from "@/qux-common/qux22/components/molecules/button";

export default {
  name: "Alert",
  components: { Button },
  props: {
    type: {
      type: String,
      default: "info",
      validator: (value) => {
        return ["error", "warning", "success", "info"].includes(value);
      },
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    timestamp: {
      type: String,
    },
    linkTitle: {
      type: String,
    },
    linkTo: {
      type: String,
    },
    actionTitle: {
      type: String,
    },
    action: {
      type: Function,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    getIcon() {
      switch (this.type) {
        case "error":
          return "mdi-minus-circle-outline";
        case "warning":
          return "mdi-alert-outline";
        case "success":
          return "mdi-check-circle-outline";
        default:
          // info
          return "mdi-information-outline";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-sheet.v-alert:not(.v-sheet--tile) {
  min-width: 319px;
  max-width: min-content;
  border-radius: 8px;
  box-shadow: $qvo-shadow-2;
  padding: 0;
  &.inline {
    min-width: min-content;
    max-width: fit-content;
    &::v-deep {
      .v-alert__content {
        align-items: center;
        flex-direction: row;
        gap: 10px;
        p {
          min-width: inherit;
          max-width: inherit;
        }
      }
      .icon,
      .v-alert__dismissible {
        align-self: flex-start;
        margin: 0;
      }
    }
  }
  &.error {
    background: $qvo-color-error;
    &::v-deep {
      .v-alert__icon,
      .v-icon {
        color: $qvo-color-error;
      }
    }
  }
  &.warning {
    background: $qvo-color-warning;
    &::v-deep {
      .v-alert__icon,
      .v-icon {
        color: $qvo-color-warning;
      }
    }
  }
  &.info {
    background: $qvo-color-info;
    &::v-deep {
      .v-alert__icon,
      .v-icon {
        color: $qvo-color-info;
      }
    }
  }
  &.success {
    background: $qvo-color-success;
    &::v-deep {
      .v-alert__icon,
      .v-icon {
        color: $qvo-color-success;
      }
    }
  }
  &::v-deep {
    .v-alert__wrapper {
      margin-left: 8px;
      padding: 24px 24px 24px 16px;
      background: $qvo-color-grey-000;
    }
    .v-alert__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        min-width: 100%;
        max-width: min-content;
      }
    }
    .v-alert__dismissible {
      color: $qvo-color-grey-900;
      margin: -4px 0 0 0;
      .mdi-close {
        color: $qvo-color-grey-900;
      }
    }
    .v-alert__dismissible,
    .title,
    .link,
    .timestamp,
    .action-button {
      align-self: flex-start;
    }
  }
}
.link {
  color: $qvo-color-blue-400;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.message {
  white-space: pre-line;
}

.v-btn:not(.v-btn--round).v-size--default {
  margin-top: 0.1875rem;
  height: 22px;
}
</style>