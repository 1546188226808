//import * as retry from 'axios-retry';
import * as axios from 'axios';
import store from '@/store/store';


const headerConfigs = (config) => {
    const auth = store.getters.getLoggedInUserIdToken || store.getters.getQFDAuthIdToken;
    config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': auth
    };
    return config;
}

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_AUTH_API,
    withCredentials: false, // This is the default
    timeout: 15000,
});

apiClient.interceptors.request.use(headerConfigs);

const configClient = axios.create({
    baseURL: process.env.VUE_APP_CONFIG_API,
    withCredentials: false, // This is the default
    timeout: 15000,
});
configClient.interceptors.request.use(headerConfigs);

export default {
    name: 'TenantService',

    async call(url, { method = 'post', ...otherParams } = {}, api) {
        // check if auth token expired
        const id = Math.floor(Math.random() * 10000);
        store.dispatch("addPendingCall", { id, url });
        let theClient;
        switch (api) {
            case "Config":
                theClient = configClient;
                break;
            default:
                theClient = apiClient;
        }
        return theClient.request({
            method,
            url,
            data: otherParams
        }).then((response) => {
            // Global handling for after API call success
            return response;
        }).catch(error => {
            // Global error handling done here

            throw (error);
        }).finally(() => {
            // Global handling for after API call completion
            store.dispatch("removePendingCall", id);
        });
    },

    getTenantList() {
        return this.call('/tenant/list', {}, "Auth");
    },

    /**
     * Retrieve the clients for said tenant
     * @param {Object} params
     * @returns an object containing configuration clients values
     */
    getClientList({ TenantId }, dispatch) {
        return this.call('/client/list', {TenantId }, 'Config').catch(error => {
            if (error.message) {
                dispatch("notifications/addNotification", {
                    status: 'error',
                    message: 'A ' + error.message + ' has occurred. Please refresh the page or try again later.',
                });
            } else {
                dispatch("notifications/addNotification", {
                    status: 'error',
                    message: 'An error has occurred. Please refresh the page or try again later.',
                });
            }
        })
    },
    
    /**
     * Retrieve the tenant configuration
     * @param {Object} params
     * @returns an object containing configuration tenant values
     */
    retrieveTenantConfiguration({ TenantId }, dispatch) {
        return this.call('/tenant/retrieve', { TenantId }, 'Config').catch(error => {
            if (error.message) {
                dispatch("notifications/addNotification", {
                    status: 'error',
                    message: 'A ' + error.message + ' has occurred. Please refresh the page or try again later.',
                });
            } else {
                dispatch("notifications/addNotification", {
                    status: 'error',
                    message: 'An error has occurred. Please refresh the page or try again later.',
                });
            }
        })
    },
};