<template>
  <nav id="dynamic-navbar">
    <slot name="navlist">
      <!-- Home page link -->
      <router-link class="nav-item" :to="homePage.linkTo">
        <div class="item-label">{{ homePage.label }}</div>
      </router-link>

      <!-- take a prop and iterate over the nav-item list -->
      <div v-for="(item, index) in navList" :key="index">
        <!-- check if the URL has the same label, set the class accordingly -->
        <div
          :class="
            routeId && routeId === item.label ? 'nav-items-active' : 'nav-items'
          "
          @mouseover="item.hover = true"
          @mouseleave="item.hover = false"
        >
          <router-link
            class="nav-item"
            :to="item.linkTo"
            @click.native="selectTab(item)"
          >
            <div class="item-label">{{ item.label }}</div>
            <div class="item-owner">{{ item.ownerName }}</div>
          </router-link>
          <v-btn
            text
            v-show="item.hover"
            @click="closeTab(item)"
            class="close-button"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </slot>
  </nav>
</template>

<script>
/**
 * @fileoverview Common component for a dynamic navigation bar
 *
 * @author Sophia Frankel <sophia.frankel@quavo.com>
 */
export default {
  name: "dynamic-navbar",
  data() {
    return {};
  },
  props: {
    /**
     * URL props needed to access correct case in the case view
     * label - Case Id
     */
    navList: {
      type: Array,
      required: true,
    },
    /**
     * Navigation object containing a label and a link to the home page
     * ex:
     *	{
     *		linkTo: {name: "Dashboard"},
     *		label: 'Dashboard'
     *	},
     */
    homePage: {
      type: Object,
      required: true,
    },
    /**
     * Function for when a tab is selected
     */
    selectTab: {
      type: Function,
      required: true,
    },
  },
  computed: {
    /**
     * Check if there is an id included in the URL
     * @returns the id included in the URL, otherwise return false
     */
    routeId() {
      if (this.$route.params.id !== undefined) {
        return this.$route.params.id;
      }
      return false;
    },
  },
  methods: {
    /**
     * Close a navigation tab
     * @param {Object} item - nav item
     */
    closeTab(item) {
      this.$emit("closeTab", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-items,
.nav-items-active {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .item-label {
    margin: auto 0;
  }

  .item-owner {
    font-size: 0.75em;
    color: $qvo-color-brand-blue-wash;
  }
}

.nav-item {
  padding: 1em 0 1em 2em;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 1.25em;
  text-align: left;
  text-decoration: none;
  &:hover,
  &:active,
  &.router-link-active {
    background: $qvo-color-brand-blue-lite;
  }
}

.nav-items:hover,
.nav-items-active {
  background: $qvo-color-brand-blue-lite;
}

.close-button {
  display: flex;
  
}
</style>