/* global _ */
import {CognitoAuth} from 'amazon-cognito-auth-js';
import IndexRouter from '@/router/index';
import * as axios from 'axios';
import store from '@/store/store';
import API from '@/services/QFDAPIService';

const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;

// This returns an object with general authentication info and behavior (including login/logout)
var auth = new CognitoAuth({
	ClientId : CLIENT_ID,
	AppWebDomain : APP_DOMAIN,
	TokenScopesArray : ['openid'],
	RedirectUriSignIn : REDIRECT_URI,
	RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
	UserPoolId : USERPOOL_ID,
});
auth.useCodeGrantFlow(); // allows us to get refresh token...

auth.userhandler = {
	onSuccess: function() {
		getUser().then(() => {
			// reroute to an actual page, only if the target is different than the current page

			let redirectURL = store.getters.getPostLoginUrl;
			if ( window.location.pathname !== redirectURL ) {
				IndexRouter.push( redirectURL ).catch( error => {
					console.info(error.message);
				});
			}
		});
	},
	onFailure: function(err) {
		console.error('Initial auth check failed');
		IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
	}
};

function getUser() {
	var jwtToken = auth.getSignInUserSession().getAccessToken().jwtToken;
	const USERINFO_URL = 'https://' + auth.getAppWebDomain() + '/oauth2/userInfo';

	var requestData = {
		headers: {
			'Authorization': 'Bearer '+ jwtToken
		}
	};
	return axios.get(USERINFO_URL, requestData).then(response => {
		return response.data;
	}).catch((error) => {
		console.error(error.message);
	});
}


export default {
	name: 'AccountService',
	logout() {
        console.log('logout');
        if (auth.isUserSignedIn()) {
            auth.signOut(); 
        }
		this.clearStorage();
	},
	clearStorage() {
		/* 
			Local storage does not self-clear.
			This makes sure we clear out all old tokens 
			without touching items we might want to keep.
		*/
		_.forIn(window.localStorage, (objKey) => {
			if (_.startsWith(objKey, 'CognitoIdentityServiceProvider')) {
				window.localStorage.removeItem(objKey);
			}
		});
	},
	getLoggedInUserInfoStorageKey(){
		var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
		var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
		var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
	
		return userInfoKey;
	},
	parseCognitoResponse(response) {
		auth.getSignInUserSession();
		return auth.parseCognitoWebResponse(response);
	},
	getSession() {
		console.info('getting new cognito session');
		this.clearStorage();
		auth.getSession();
	},
	async refreshSession(refToken) {
		this.clearStorage();
		await auth.refreshSession(refToken);
	},
	getAccessToken() {
		return auth.getSignInUserSession().getAccessToken();
	},
	getRefreshToken() {
		return auth.getSignInUserSession().getRefreshToken().getToken();
	},
	getLoggedInUserIdToken() {
		return "Bearer " + auth.getSignInUserSession().getIdToken().jwtToken;
	},
	getLoggedInUserInfo() {
		return auth.getSignInUserSession().getIdToken().decodePayload();
	},
	getUser() {
		var jwtToken = auth.getSignInUserSession().getAccessToken().jwtToken;
		const USERINFO_URL = 'https://' + auth.getAppWebDomain() + '/oauth2/userInfo';

		var requestData = {
			headers: {
				'Authorization': 'Bearer ' + jwtToken
			}
		};
		return axios.get(USERINFO_URL, requestData).then(response => {
			return response.data;
		}).catch((error) => {
			console.error(error.message);
		});
	},
	isUserSignedIn() {
		return auth.isUserSignedIn();
	},
	getTeamMembers(tenantId) {
		return API.call('/user/operator/list', { "tenantId": tenantId });
	},
	saveUser(userToSave) {
		return API.call('/user/operator/create', {
			"tenantId": userToSave.tenantId,
			"emailAddress": userToSave.emailAddress,
			"userId": userToSave.userId,
			"isDisabled": userToSave.isDisabled,
			"name": userToSave.name,
			"accessRoles": userToSave.accessRoles,
			"isAllowedAllClients": userToSave.isAllowedAllClients,
			"allowedClients": userToSave.allowedClients
		});
	},
	deleteUser(payload) {
		return API.call('/user/operator/delete', {
			"userId": payload.userId,
			"tenantId": payload.tenantId
		});
	},
};

