<!--
	The Notification Center is a global alert utility that provides a home for all forms of feedback.
	The notifications displayed here can come in one of four statuses: success, info, warning, or error.
	The items will generally dismiss themselves automatically after a brief delay.

	HOW TO:  Checkout the store/modules/notification.js file for implementation details.

	NOTE: For unique cases, simply use a v-alert component within your view.
		Do NOT use the notification center when your alert:
		- must display in context on the page
		- includes HTML like media and actions
		- cannot be dismissed / is a persistent element on the page
-->

<template>
	<div id="notification-center">
		<div
			class="notification"
			v-for="(notification, index) in this.$store.state.notifications.notificationList"
			:key="index"
		>
			<notification :notification="notification" />
		</div>
	</div>
</template>

<script>
	import notification from '@/qux-common/components/notification';

	export default {
		name: "notificationCenter",
		components: {
			notification,
		},
	};
</script>

<style lang="scss" scoped>
#notification-center {
	position: fixed;
	width: clamp(5em, 25em, calc(100% - 4em));
	top: 2em;
	right: 2em;
	z-index: 999;

	.v-alert {
		position: relative;
		right: 0;

		&[data-auto-dismiss="true"] {
			animation-name: dismiss-notification;
			animation-duration: 1s;
			animation-direction: normal;
			animation-fill-mode: forwards;
		}
	}
}

@keyframes dismiss-notification {
	0% { // begin with baseline right and opacity values
		right: 0;
		opacity: 1;
	}
	80% { // make completely transparent & offset. Begin considering top margin
		opacity: 0;
		margin-top: 0;
		right: -100%;
	}
	99% { // use negative margin to move the entire list upward
		margin-top: -4em;
		right: -100%;
	}
	100% { // make sure the item is invisible and out of the way
		position: absolute;
		opacity: 0;
	}
}
</style>