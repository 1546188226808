
/**
 * ConfigurationService.js contains all of the service functions used for configuration.
 *
 * @endpoints /configuration/
 * 	retrieve - retrieve the configuration
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import API from '@/services/QFDAPIService';

export default {
    name: 'ConfigurationService',
    /**
     * Retrieve the configuration data
     * @param {Object} params
     * @returns an object containing configuration values
     */
    retrieve(dispatch) {
        return API.call('/configuration/retrieve').catch(error => {
            console.error('config error', error);
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if(error.response.data.StatusList){
					message = "Error retrieving claim details. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
        })
    },
}
