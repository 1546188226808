<template>
  <alert
    :title="notification.title"
    :message="notification.message"
    :type="notification.status"
    dismissible
    @close="deleteNotification"
  />
</template>
  
<script>
import alert from "@/qux-common/qux22/components/molecules/alert";

export default {
  name: "Notification",
  components: {
    alert,
  },
  data() {
			return {
				timeout: null,
			};
		},
  props: {
    notification: {
      type: Object,
    },
  },
  methods: {
    deleteNotification() {
      // delay so that alert closing animation can complete
      setTimeout(() => {
        this.$store.dispatch("notifications/deleteNotification", this.notification);
      }, 500);
    },
  },
  mounted() {
			this.timeout = setTimeout(
				() =>
					this.$store.dispatch(
						"notifications/deleteNotification",
						this.notification
					),
				this.notification.dismissTimer * 1000
			);
		},
};
</script>
  
<style lang="scss" scoped>
</style>