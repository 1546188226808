<template>
	<v-btn class="back-nav-btn" text @click="action">
		<v-icon>mdi-arrow-left</v-icon>
		<slot>Back</slot>
	</v-btn>
</template>

<script>
	export default {
		name: 'backNavBtn',
		props: {
			action: {
				type: Function,
				required: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	#qvo-app .back-nav-btn {
		margin: -1em 0 2em;
		padding: 0;
	}
</style>