<template>
  <header id="titlebar">
		<slot name="logo">
			<!--
				You can specify the name of your file as a param
					the param will work for all standard image file types
				linking to "/" is the expected behavior from a logo
					only deviate from the given router-link with good cause 
			-->
			<router-link to="/">
				<img :src="require('@/assets/imgs/QFD-Full-White.svg')"
					class="logo"
					alt="QFD"
					width="150"
					height="auto"
				/>
			</router-link>
		</slot>
	</header>
</template>

<script>
	export default {

	}
</script>

<style>

</style>