<template>
	<label
		class="togglebutton"
		:class="[name,{checked: toggled}]"
		tabindex="0"
		role="checkbox"
		@keydown.space.prevent="keyToggle">
		<input
			class="togglebutton-input"
			type="checkbox"
			:disabled="disabled"
			@change.stop="toggle"
			tabindex="-1"/>
		<span class="togglebutton-switch"></span>
		<span class="inner-label" :class="{invisible: !labels}">
			<!-- the ... works to create spacing, it's invisible anyway -->
			<slot>...</slot>
		</span>
	</label>
</template>

<script>
export default {
	name: 'togglebutton',
	props: {
		name: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		labels: {
			type: Boolean,
			default: false
		},
		value: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			toggled: !!this.value,
		}
	},
	methods: {
		keyToggle(event) {
			if (this.disabled) {
				return
			}
			this.toggle(event);
		},
		toggle(event) {
			this.toggled = !this.toggled;
			this.$emit('input', this.toggled);
			this.$emit('change', {
				value: this.toggled,
				srcEvent: event
			});
		}
	}
}
</script>

<style lang="scss">
	@import '@/qux-common/design/_variables';


	.togglebutton {
		display: inline-block;
		position: relative;
		padding: 0.5em 1em 0.5em 3em;
		background: $qvo-color-bg-gray;
		border-radius: 2em;
		line-height: 1.5;
		cursor: pointer;
		transition: all 300ms ease-in-out;

		&.checked {
			background: $qvo-color-brand-blue;
			padding: 0.5em 3em 0.5em 1em;
			color: #fff;
		}

		& .inner-label.invisible {
			opacity: 0;
		}
	}

	.togglebutton-input {
		position: absolute;
		opacity: 0;
	}

	.togglebutton-switch {
		position: absolute;
		left: 0.2em;
		right: auto;
		top: 0.25em;
		display: inline-block;
		width: 2em;
		height: 2em;
		vertical-align: middle;
		background: #fff;
		border-radius: 50%;
		transition: all 300ms ease-in-out;

		.checked & {
			left: auto;
			right: 0.2em;
		}
	}
</style>
