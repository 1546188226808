import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/store';
import AccountService from '@/services/AccountService';


async function requireAuth(to, from, next) {
	let isLoggedIn = AccountService.isUserSignedIn();

	// if not logged in, let's get the login form
	if (!isLoggedIn) {
		console.info('retreiving hosted login form');
		await AccountService.getSession();
	}

	// grab the tokens if either is missing
	if (isLoggedIn && (!store.getters.getAccessToken || !store.getters.getRefreshToken)) {
		await store.dispatch("getAccessToken");
		await store.dispatch("getRefreshToken");
	}

	// Get info about the user if we don't have it
	if (isLoggedIn && !store.getters.getLoggedInUserId) {
		await store.dispatch('getLoggedInUserInfo');
		await store.dispatch('getTenantList');
	}

	next(); // continue navigation
}

function requireTrailingSlash(to, from, next) {
	if (!to.path.endsWith('/')) {
		next({ path: `${to.path}/`, query: to.query, hash: to.hash });
	} else {
		next();
	}
}

/**
 * Cognito does not support embedded iframe authentication, so we are using custom qfd authentication
 */
async function requireQFDAuth(to, from, next) {
	if (!store.getters.getAuthAccessToken) {
		await store.dispatch("getAuthAccessToken");
		await store.dispatch("setQFDAuthToken", to.params.tokenId);
	}

	const token = store.getters.getQFDAuthToken;
	
	let isLoggedIn = AccountService.isUserSignedIn();
	// if not logged in, let's get the login form
	if (!isLoggedIn) {
		console.info('refreshing session');
		await AccountService.refreshSession(token.RefreshToken);
		await store.commit("SET_REFRESH_TOKEN", token.RefreshToken);
	}

	if (token.RequestPayload.TenantId) {
		await store.dispatch("setActiveTenantId", token.RequestPayload.TenantId);
	}
	if (token.RequestPayload.ClientId) {
		await store.dispatch("setActiveClientId", token.RequestPayload.ClientId);
	}
	switch (token.RequestPayload.Details.Action) {
		case "Create": {
			await store.dispatch("setIntakePrepopulatedData", token.RequestPayload.Details);
			store.dispatch("startIntake");
			next({ name: 'Embedded Create Claim' });
			break;
		}
		case "Status":
			next({ name: 'Embedded Case View', params: { id: token.RequestPayload.Details.CaseId } });
			break;
		case "Tasks":
			next({ name: 'Embedded Tasks' });
			break;
		default:
			// continue navigation
			next();
	}
}

const routes = [
	{
		path: '/logout',
		name: 'Logout',
		redirect: '/login',
		beforeEnter() {
			store.dispatch('logout');
		}
	},
	{
		path: '/login',
		name: 'Login',
		beforeEnter() {
			AccountService.getSession();
		}
	},
	{
		path: '/login/oauth2/code/cognito',
		beforeEnter() {
			//if we end up here but are already loggedin - just let the redirect handle it...
			if (!AccountService.isUserSignedIn()) {
				var currUrl = window.location.href;
				AccountService.parseCognitoResponse(currUrl);
			}
		}
	},
	{
		path: '/token/:tokenId',
		name: 'Token',
		beforeEnter: requireQFDAuth,
		component: () => import(/* webpackChunkName: "configuration" */ '../views/dashboard.vue'),
	},
	{
		path: '/embedded/case-view/:id/:action?/:actionId?',
		name: 'Embedded Case View',
		component: () => import(/* webpackChunkName: "caseView" */ '@/views/case')
	},
	{
		path: '/embedded/create-claim/',
		name: 'Embedded Create Claim',
		beforeEnter: requireTrailingSlash,
		component: () => import(/* webpackChunkName: "createClaim" */ '@/views/create-claim')
	},
	{
		path: '/embedded/tasks',
		name: 'Embedded Tasks',
		component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks')
	},
	{
		path: '/',
		component: () => import(/* webpackChunkName: "layout" */ '@/views/layout'),
		children: [
			{
				path: '/',
				beforeEnter: requireAuth,
				component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard')
			},
			{
				path: '/dashboard',
				redirect: '/',
			},
			{
				path: '/case-view/:id/:action?/:actionId?',
				name: 'Case View',
				beforeEnter: requireAuth,
				component: () => import(/* webpackChunkName: "caseView" */ '@/views/dashboard')
			},
			{
				path: '/create-claim/',
				name: 'Create Claim',
				beforeEnter: async (to, from, next) => {
					await requireTrailingSlash(to, from, next);
					await requireAuth(to, from, next);
				},
				component: () => import(/* webpackChunkName: "createClaim" */ '@/views/dashboard')
			},
			{
				path: '/task/:type',
				name: 'Task View',
				beforeEnter: requireAuth,
				component: () => import(/* webpackChunkName: "task" */ '@/views/dashboard')
			},
			{
				path: '/tasks',
				beforeEnter: requireAuth,
				component: () => import(/* webpackChunkName: "tasks" */ '@/views/dashboard')
			},
			{
				path: '*',
				name: 'NotFound',
				component: () => import(/* webpackChunkName: "NotFound" */ '../views/notfound.vue')
			}
		],
	},







];

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
});


router.beforeEach((to, from, next) => {
	// store the intended location so we can put the user back where they meant to be
	let storageWindow = window.sessionStorage;
	let target = store.getters.getPostLoginUrl;

	/*
		If the to.path isn't cognito, then trust it.
		Otherwise, we'll check for a locally stored path.
		If neither works, we'll just trust whatever vue already has.
	*/
	if (!to.path.includes('/login')) {
		target = to.path;
	} else if (storageWindow.getItem('qvo.targetUrl')) {
		target = storageWindow.getItem('qvo.targetUrl');
	}

	// make extra sure we're not looping on the cognito screen
	if (target.includes('/login') || target.includes('/logout')) {
		target = "/";
	}

	// update the store & local storage to cover our bases
	store.dispatch('setPostLoginUrl', target);
	storageWindow.setItem('qvo.targetUrl', target);

	next();
});

export default router;