/**
 * Define all data and data-interactions for Account.
 *
 * @author David Barnett <david.barnett@quavo.com>
*/

import AccountService from '@/services/AccountService.js';
import QFDAuthService from '@/services/QFDAuthService.js';
import TenantService from '@/services/TenantService';

export const state = {
	loggedInUser: {},	// object containing data for the user currently logged in
	loggedInUserIdToken: '',	// auth token for user
	tenantId: '',	// tenantId assigned to the user
	loggedInUserEmail: '',	// user email
	loggedInUserName: '',	// user username
	loggedInUserId: '',	// user Id
	postLoginUrl: '/dashboard', // redirect URL
	teamMembers: [],	// list of team members associated with the user's tenantId
	teamMembersError: false,	// team member error flag
	inactiveTeamMembers: [],	// list of inactive team members
	activeTeamMembers: [],	// list of active team members
	showAllTenantUsers: false,	// flag for displaying all of the tenant users
	roles: [],
	/**
	 * Clients list
	 * need a way to pull a list for this for actual clients
	 */
	clients: [],
	activeClientId: '',	// clientId of the active client
	/**
	 * List of tenant objects
	 */
	tenantList: [{
		tenantLabel: '',
		tenantId: ''
	}],
	activeTenantLabel: '',	// label for the active tenant
	activeTenantId: '',	// tenantId of the active tenant
	activeTenant: {}, // active tenant details
	accessToken: {},	// access token object
	refreshToken: {},	// refresh token object
	accessTokenExpiration: '',	// access token expiration time
	accessTokenIssueTime: '',	// access token issue time
	authAccessToken: '', // Auth Access Token
	authAccessTokenExpiration: '',	// Auth access token expiration time
	authAccessTokenIssueTime: '',	// Auth access token issue time
	qfdAuthToken: {}, // QFD Auth Token
};

/*
	Actions hook up to the interface, but they do not touch the store data directly.
	They can be rather complicated and will generally lean on one or more mutations.
	Actions are also the communication layer with external sources (API).

	Actions come with access to four contexts ({ state, getters, commit, dispatch })
		state = direct data access... avoid use
		getters = indirect data access... this is good
		commit = call a mutation
		dispatch = for us, dispatch will almost certainly be an axios api call
*/
export const actions = {
	/**
	 * Get the current user list from cognito, matching the tenantId for the currently logged in user.
	 * Clear out the derived active and inactive lists to protect against stale data.
	 * @param {*} contexts - commit, dispatch, and getters
	 * @param {*} tenantId - tenantId for request body
	 */
	setTeamMembers({ commit, dispatch, getters }, tenantId) {
		dispatch("clearTeamMembers");
		AccountService.getTeamMembers(tenantId).then(async (response) => {
			if (response.data.length < 1 && !getters.getShowAllTenantUsers) {
				// the call should always return at least one user when viewing a tenant...
				throw 'No users returned for the tenant.';
			}
			await commit("SET_TEAM_MEMBERS", response.data);
			await commit("SET_TEAM_MEMBERS_ERROR", false);
			await dispatch("separateTeamMembers");
		}).catch(error => {
			console.error('Could not retreive the teammember list.', error.response);
			commit("SET_TEAM_MEMBERS_ERROR", true);
			dispatch("notifications/addNotification", {
				status: 'error',
				message: 'An error occurred while attempting to get the team list.',
			});
		});
	},
	/**
	 * Clear team member data
	 * @param {*} contexts - commit
	 */
	clearTeamMembers({ commit }) {
		commit("SET_TEAM_MEMBERS", []);
		commit("SET_INACTIVE_TEAM_MEMBERS", []);
		commit("SET_ACTIVE_TEAM_MEMBERS", []);
	},
	/**
	 * Set flag for displaying all tenant users
	 * @param {*} contexts - commit
	 * @param {Boolean} shouldShow - show all tenant users flag
	 */
	setShowAllTenantUsers({ commit }, shouldShow) {
		shouldShow = Boolean(shouldShow);
		commit("SET_SHOW_ALL_TENANT_USERS", shouldShow);
	},
	/**
	 * Separate team members by active status
	 * @param {*} contexts -  commit and getters
	 */
	separateTeamMembers({ commit, getters }) {
		let teamMembers = getters.getTeamMembers;

		let inactiveMembers = [];
		let activeMembers = [];

		teamMembers.map(member => {
			if (member.isDisabled) {
				inactiveMembers.push(member);
			} else {
				activeMembers.push(member);
			}
		});

		commit("SET_INACTIVE_TEAM_MEMBERS", inactiveMembers);
		commit("SET_ACTIVE_TEAM_MEMBERS", activeMembers);
	},
	/**
	 * Save the user. Update team members when necessary.
	 * @param {*} contexts - commit, dispatch, and getters
	 * @param {Object} editedUser - user object to be saved
	 */
	saveUser({ commit, dispatch, getters }, editedUser) {
		AccountService.saveUser(editedUser).then(async () => {
			if (editedUser.userId === getters.getLoggedInUserId) {
				await commit("UPDATE_LOGGED_IN_USER", editedUser);
			}
			else if (editedUser.userId) {
				await commit("UPDATE_USER", editedUser);
				await dispatch("separateTeamMembers");
			} else {
				// almost certainly a new user - let's update the list
				await dispatch("setTeamMembers", getters.getActiveTenantId);
			}
			dispatch("notifications/addNotification", {
				status: 'success',
				message: 'The user ' + editedUser.name + ' has been updated.',
			});
		}).catch(error => {
			console.error('Could not save user, ' + editedUser.name + ':', error.response);
			dispatch("notifications/addNotification", {
				status: 'error',
				message: 'An error occurred while attempting to save the user.',
			});
		});
	},
	/**
	 * Delete a user. Update the team members.
	 * @param {*} contexts - commit and dispatch
	 * @param {Object} payload - user object to be deleted
	 */
	deleteUser({ commit, dispatch }, payload) {
		AccountService.deleteUser(payload).then(async () => {
			await commit("DELETE_USER", payload.userId);
			await dispatch("setTeamMembers", payload.tenantId);
			dispatch("notifications/addNotification", {
				status: 'success',
				message: 'The user has been deleted.',
			});
		}).catch(error => {
			console.error('Could not delete the user:', error.response);
			dispatch("notifications/addNotification", {
				status: 'error',
				message: 'An error occurred while attempting to delete the user.',
			});
		});
	},
	/**
	 * Logout and clear login data
	 * @param {*} contexts - commit
	 */
	async logout({ commit }) {
		console.log('store logout')
		await AccountService.logout();
		commit("SET_LOGGEDIN_USER", '');
		commit("SET_LOGGEDIN_USER_ID_TOKEN", '');
		commit("SET_LOGGEDIN_USER_ID", '');
		commit("SET_LOGGEDIN_USER_EMAIL", '');
		commit("SET_LOGGEDIN_USER_NAME", '');
		commit("SET_TENANT_ID", '');
		commit("SET_ACTIVE_TENANT", '');
		commit("SET_ACCESS_TOKEN", {});
		commit("SET_ACCESS_TOKEN_EXPIRATION", '');
		commit("SET_ROLES", []);
	},
	/**
	 * Retrieve access token
	 * @param {*} contexts - commit
	 */
	async getAccessToken({ commit }) {

		let accessToken = await AccountService.getAccessToken();
		commit("SET_ACCESS_TOKEN", accessToken);

		let tokenJson = JSON.parse(JSON.stringify(accessToken));
		commit("SET_ACCESS_TOKEN_EXPIRATION", tokenJson.payload.exp);
		commit("SET_ACCESS_TOKEN_ISSUE_TIME", tokenJson.payload.iat);
	},
	/**
	 * Retrieve auth access token
	 * @param {*} contexts - commit
	 */
	async getAuthAccessToken({ commit }) {
		const issueTime = Date.now();
		commit("SET_AUTH_ACCESS_TOKEN_ISSUE_TIME",  issueTime);
		let accessToken = await QFDAuthService.getAuthAccessToken();
		commit("SET_AUTH_ACCESS_TOKEN",  "Bearer " + accessToken.data.access_token);
		commit("SET_AUTH_ACCESS_TOKEN_EXPIRATION", issueTime + (accessToken.data.expires_in * 1000));
	},
	/**
	 * Retrieve refresh token
	 * @param {*} contexts - commit
	 */
	async getRefreshToken({ commit }) {
		let refreshToken = await AccountService.getRefreshToken();
		commit("SET_REFRESH_TOKEN", refreshToken);
	},
	/**
	 * Refresh the session and retrieve a new access token
	 * @param {*} contexts - dispatch and getters
	 */
	async refreshSession({ dispatch, getters }) {
		await AccountService.refreshSession(getters.getRefreshToken);
		await dispatch("getAccessToken");
	},
	/**
	 * Retrieve the logged in user's details from the auth service and set the active tenant.
	 * The tenantId from the user is used for our API calls to cognito.
	 * @param {*} contexts - commit 
	 */
	async getLoggedInUserInfo({ commit }) {
		const rawUserObj = await AccountService.getLoggedInUserInfo();
		const accessDetails = JSON.parse(rawUserObj['custom:accessDetails']);
		const masterTenant = accessDetails.find(e => e.tenantId === "master");
		let userObj = {
			userId: rawUserObj.sub,
			name: rawUserObj.name,
			emailAddress: rawUserObj.email,
			accessRoles: accessDetails[0]['accessRoles'], //intially hard coding this to accessDetails instance in index 0
			tenantId: accessDetails[0]['tenantId'], //intially hard coding this to accessDetails instance in index 0
			isDisabled: false
		}

		if (accessDetails[0]['allowedClients']) {
			userObj.allowedClients = accessDetails[0]['allowedClients']; //intially hard coding this to accessDetails instance in index 0
		} else {
			userObj.isAllowedAllClients = true;
		}
		if (masterTenant && masterTenant.defaultTenantId) {
			userObj.tenantId = masterTenant.defaultTenantId;
			const defaultTenant =  accessDetails.find(e => e.tenantId === masterTenant.defaultTenantId);
			if (defaultTenant) {
				userObj.accessRoles = defaultTenant.accessRoles;
			}
			commit("SET_ACTIVE_TENANT_ID", userObj.tenantId);
		}

		commit("SET_LOGGEDIN_USER", userObj);
		commit("SET_LOGGEDIN_USER_ID", userObj.userId);
		commit("SET_LOGGEDIN_USER_EMAIL", userObj.emailAddress);
		commit("SET_LOGGEDIN_USER_NAME", userObj.name);
		commit("SET_TENANT_ID", userObj.tenantId);
		commit("SET_ROLES", userObj.accessRoles.split(","));

		// store the raw token to send back to Quavo's API
		let loggedInUserIdToken = await AccountService.getLoggedInUserIdToken();
		await commit("SET_LOGGEDIN_USER_ID_TOKEN", loggedInUserIdToken);
	},
	/**
	 * Set Active Client Id
	 * @param {*} contexts - commit
	 */
	setActiveClientId({ commit }, clientId) {
		commit("SET_ACTIVE_CLIENT_ID", clientId);
	},
	/**
	 * Set Active TenantId Id
	 * @param {*} contexts - commit
	 */
	async setActiveTenantId({ commit, dispatch }, tenantId) {
		commit("SET_ACTIVE_TENANT_ID", tenantId);
		await dispatch("retrieveTenantConfiguration", tenantId);
		dispatch("getClientList", tenantId);
	},
	async getClientList({commit, dispatch}, tenantId) {
		if (tenantId !== undefined) {
			TenantService.getClientList({TenantId: tenantId}).then(async (response) => {
				if (response.data.ClientInfoList) {
					const clientList = response.data.ClientInfoList.map(item => {
						return {
							clientId: item.ClientId,
							clientLabel: item.ConfigLabel
						}
					});
					commit('SET_CLIENT_LIST', clientList);
				} else {
					console.error('Could not retreive the client list.');
					dispatch("notifications/addNotification", {
						status: 'error',
						message: 'An error occurred while attempting to get the client list.',
					});
				} 
			}).catch(error => {
				console.error('Could not retreive the tenant configuration.', error.response);
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'An error occurred while attempting to get the client list.',
				});
			});
		}
	},
	async getTenantList({commit, dispatch}, tenantId) {
		TenantService.getTenantList().then(async (response) => {
			if (response.data.tenantList && response.data.tenantList.length > 0) {
				commit('SET_TENANT_LIST', response.data);
				
				// set default tenant choice
				let tenantObj = response.data.tenantList[0];

				let sessionObj = JSON.parse(window.sessionStorage.getItem('selectedTenant'));
				if (sessionObj && sessionObj.tenantId) {
					tenantObj = sessionObj;
				}

				commit('SET_ACTIVE_TENANT', tenantObj);
				await dispatch("retrieveTenantConfiguration", tenantObj.tenantId);
				dispatch("getClientList", tenantObj.tenantId);
			} else {
				console.error('Could not retreive the tenant list.');
				dispatch("notifications/addNotification", {
					status: 'error',
					message: 'An error occurred while attempting to get the tenant list.',
				});
			}
		}).catch(error => {
			console.error('Could not retreive the tenant list.', error.response);
			dispatch("notifications/addNotification", {
				status: 'error',
				message: 'An error occurred while attempting to get the tenant list.',
			});
		});
		if (tenantId !== undefined && tenantId !== "master") {
			// set the initial tenant
			dispatch('setActiveTenant', tenantId);
		}
	},

	async retrieveTenantConfiguration({commit, dispatch}, tenantId) {
		if (tenantId !== undefined) {
			await TenantService.retrieveTenantConfiguration({TenantId: tenantId}).then(async (response) => {
				if (response.data.tenantConfiguration) {
					let sessionObj = JSON.parse(window.sessionStorage.getItem('selectedTenant'));
					let tenantObj = {};
					if (sessionObj && sessionObj.tenantId) {
						tenantObj = sessionObj;
					}
					tenantObj.engineURL = response.data.tenantConfiguration.EngineURL;
					tenantObj.tenantId = response.data.tenantConfiguration.TenantId;
					commit('SET_ACTIVE_TENANT', tenantObj);
				} else {
					console.error('Could not retreive the tenant configuration.');
					dispatch("notifications/addNotification", {
						status: 'error',
						message: 'An error occurred while attempting to get the tenant configuration.',
					});
				} 
			});
		}
	},

	/**
	 * Recall where the system should go after hosted form redirect
	 * @param {*} contexts - commit
	 * @param {String} urlPath - new url path
	 */
	setPostLoginUrl({ commit }, urlPath) {
		// avoid circular redirects if they begin on the hosted form
		if (urlPath.includes('oath2')) {
			urlPath = "/dashboard";
		}
		commit("SET_POST_LOGIN_URL", urlPath);
	},
	/**
	 * Set the active tenant based on the provided tenantId
	 * @param {*} contexts - commit, dispatch, and getters
	 * @param {*} tenantId - new tenantId
	 */
	async setActiveTenant({ dispatch, getters }, tenantId) {
		let tenantObj = null;

		if (tenantId === 'master') {
			// no need to lookup anything further for Master, just set it manually
			tenantObj = {
				tenantId: "master",
				tenantLabel: "QUAVO ADMIN"
			};
		} else if (tenantId !== '') {
			let tenantList = getters.getTenantList;
			if (tenantList.length > 1) {
				// Multi-Tenant User - Compare selection against full list
				let match = tenantList.find(obj => {
					return obj.tenantId === tenantId;
				});
				tenantObj = match ? match : null;
			} else {
				// Single-Tenant User - Just take this value as-is without lookup
				tenantObj = {
					tenantId: tenantId,
					tenantLabel: ''
				};
			}
		} else {
			// clear the object, useful for logout
			tenantObj = {
				tenantId: '',
				tenantLabel: ''
			};
		}

		if (tenantObj !== null && tenantObj.tenantId) {
			await dispatch("retrieveTenantConfiguration", tenantObj.tenantId);
			dispatch("getClientList", tenantObj.tenantId);
		} else {
			dispatch("notifications/addNotification", {
				status: 'error',
				message: 'An error occurred while attempting to set the active tenant.',
			});
		}
	},
	/**
	 * Clear any lingering data
	 * @param {*} contexts - commit
	 */
	clearAccountState({ commit }) {
		commit("SET_TEAM_MEMBERS", []);
	},
	/**
	 * Set QFD Auth Token By Id
	 * @param {*} contexts - commit
	 */
	async setQFDAuthToken({ commit }, token) {
		const tokenData = await QFDAuthService.getQFDAuthToken(token);
		commit("SET_QFD_AUTHTOKEN", tokenData);
	},
};

/*
	Mutations are methods that act on the state data directly.
	They should be incredibly basic and perform a single task.
	
	Mutations always take in the current 'state' and a payload.
*/
export const mutations = {
	/**
	 * Set the qfd auth token, used for embedded views
	 * @param {*} state - current state
	 * @param {Array} team - qfd auth token
	 */
	SET_QFD_AUTHTOKEN(state, qfdAuthToken) {
		state.qfdAuthToken = qfdAuthToken;
	},
	/**
	 * Set the teamMembers list
	 * @param {*} state - current state
	 * @param {Array} team - list of team members
	 */
	SET_TEAM_MEMBERS(state, team) {
		state.teamMembers = team;
	},
	/**
	 * Set the teamMembersError flag
	 * @param {*} state - current state
	 * @param {Boolean} value - error value
	 */
	SET_TEAM_MEMBERS_ERROR(state, value) {
		state.teamMembersError = value;
	},
	/**
	 * Set the showAllTenantUsers flag
	 * @param {*} state - current state
	 * @param {Boolean} value - show all users flag
	 */
	SET_SHOW_ALL_TENANT_USERS(state, value) {
		state.showAllTenantUsers = value;
	},
	/**
	 * Set the inactiveTeamMembers list
	 * @param {*} state - current state
	 * @param {Array} team - inactive team members
	 */
	SET_INACTIVE_TEAM_MEMBERS(state, team) {
		state.inactiveTeamMembers = team;
	},
	/**
	 * Set the activeTeamMembers list
	 * @param {*} state - current state
	 * @param {Array} team - active team members
	 */
	SET_ACTIVE_TEAM_MEMBERS(state, team) {
		state.activeTeamMembers = team;
	},
	/**
	 * Update user based on the user object provided
	 * @param {*} state - current state
	 * @param {Object} editedUser - user object to be updated
	 */
	UPDATE_USER(state, editedUser) {
		var member = state.teamMembers.find(member => member.userId === editedUser.userId);
		member.update = false;
		member.name = editedUser.name;
		member.emailAddress = editedUser.emailAddress;
		member.accessRoles = editedUser.accessRoles;
		member.isAllowedAllClients = editedUser.isAllowedAllClients;
		member.allowedClients = editedUser.allowedClients;
		member.isDisabled = editedUser.isDisabled;
	},
	/**
	 * Delete a user by removing it from the teamMembers list
	 * @param {*} state - current state
	 * @param {String} id - userId for the deleted user
	 */
	DELETE_USER(state, id) {
		var target = state.teamMembers.findIndex(item => item.userId === id);
		state.teamMembers.splice(target, 1);
	},
	/**
	 * update the logged in user info
	 * @param {*} state - current state
	 * @param {Object} user - user object representing the logged in user
	 */
	UPDATE_LOGGED_IN_USER(state, user) {
		state.loggedInUser = user;
		state.loggedInUserEmail = user.emailAddress;
		state.loggedInUserName = user.name;
	},
	/**
	 * Set the logged in user info
	 * @param {*} state - current state
	 * @param {Object} userObj - user object representing the logged in user
	 */
	SET_LOGGEDIN_USER(state, userObj) {
		state.loggedInUser = userObj;
	},
	/**
	 * Set the logged in userId token
	 * @param {*} state - current state
	 * @param {String} token - access token for the logged in user
	 */
	SET_LOGGEDIN_USER_ID_TOKEN(state, token) {
		state.loggedInUserIdToken = token;
	},
	/**
	 * Set the logged in user email
	 * @param {*} state - current state
	 * @param {String} email - user email
	 */
	SET_LOGGEDIN_USER_EMAIL(state, email) {
		state.loggedInUserEmail = email;
	},
	/**
	 * Set logged in user name
	 * @param {*} state - current state
	 * @param {String} name - user name
	 */
	SET_LOGGEDIN_USER_NAME(state, name) {
		state.loggedInUserName = name;
	},
	/**
	 * Set user tenant Id
	 * @param {*} state - current state
	 * @param {String} tenantId - new tenantId
	 */
	SET_TENANT_ID(state, tenantId) {
		state.tenantId = tenantId;
	},
	/**
	 * Set the logged in user Id
	 * @param {*} state - current state
	 * @param {String} userId - new userId
	 */
	SET_LOGGEDIN_USER_ID(state, userId) {
		state.loggedInUserId = userId;
	},
	/**
	 * Set the post login URL
	 * @param {*} state - current state
	 * @param {String} urlPath - new URL path
	 */
	SET_POST_LOGIN_URL(state, urlPath) {
		state.postLoginUrl = urlPath;
	},
	/**
	 * Set the client list
	 * @param {*} state - current state
	 * @param {Array} list - new tenant list
	 */
	SET_CLIENT_LIST(state, list) {
		state.clients = list;
	},
	/**
	 * Set the tenant list
	 * @param {*} state - current state
	 * @param {Array} list - new tenant list
	 */
	SET_TENANT_LIST(state, list) {
		state.tenantList = list;
	},
	/**
	 * Set active client Id
	 * @param {*} state - current state
	 * @param {String} clientId - new client
	 */
	SET_ACTIVE_CLIENT_ID(state, clientId) {
		state.activeClientId = clientId;
	},
	/**
	 * Set active tenant Id
	 * @param {*} state - current state
	 * @param {String} tenantId - new tenant
	 */
	SET_ACTIVE_TENANT_ID(state, tenantId) {
		const tenantObj = {
			tenantId: tenantId
		}
		window.sessionStorage.setItem('selectedTenant', JSON.stringify(tenantObj));
		state.activeTenantId = tenantId;
	},
	/**
	 * Set the active tenant for admin users to view different tenants.
	 * Save the tenant object in the session storage.
	 * @param {*} state - current state
	 * @param {Object} tenantObj - tenant object
	 */
	SET_ACTIVE_TENANT(state, tenantObj) {
		window.sessionStorage.setItem('selectedTenant', JSON.stringify(tenantObj));
		state.activeTenantId = tenantObj.tenantId;
		state.activeTenantLabel = tenantObj.tenantLabel;
		state.activeTenant = tenantObj;
		state.tenantId = tenantObj.tenantId;
		const loggedInUserInfo = AccountService.getLoggedInUserInfo();
		if (loggedInUserInfo && loggedInUserInfo['custom:accessDetails']) {
			const accessDetails = JSON.parse(loggedInUserInfo['custom:accessDetails']);
			const newTenant = accessDetails.find(e => e.tenantId === tenantObj.tenantId) || accessDetails.find(e => e.tenantId === "master");
			if (newTenant) {
				state.roles = newTenant.accessRoles;
			}
		}
		
	},
	/**
	 * Set the access token
	 * @param {*} state - current state
	 * @param {String} token - new access token
	 */
	SET_ACCESS_TOKEN(state, token) {
		state.accessToken = token;
	},
	/**
	 * Set the access token
	 * @param {*} state - current state
	 * @param {String} token - new access token
	 */
	SET_AUTH_ACCESS_TOKEN(state, token) {
		state.authAccessToken = token;
	},
	/**
	 * Set the refresh token
	 * @param {*} state - current state
	 * @param {String} token - new refresh token
	 */
	SET_REFRESH_TOKEN(state, token) {
		state.refreshToken = token;
	},
	/**
	 * Set the access token expiration in milliseconds
	 * @param {*} state - current state
	 * @param {Int} rawTime - raw time in seconds
	 */
	SET_ACCESS_TOKEN_EXPIRATION(state, rawTime) {
		// convert to milliseconds for date math
		state.accessTokenExpiration = rawTime === '' ? '' : rawTime * 1000;
	},
	/**
	 * Set the access token issue time in milliseconds
	 * @param {*} state - current state
	 * @param {Int} rawTime - raw time in seconds
	 */
	SET_ACCESS_TOKEN_ISSUE_TIME(state, rawTime) {
		// convert to milliseconds for date math
		state.accessTokenIssueTime = rawTime === '' ? '' : rawTime * 1000;
	},
	/**
	 * Set the access token expiration in milliseconds
	 * @param {*} state - current state
	 * @param {Int} rawTime - raw time in milliseconds
	 */
	SET_AUTH_ACCESS_TOKEN_EXPIRATION(state, msTime) {
		// convert to milliseconds for date math
		state.authAccessTokenExpiration = msTime;
	},
	/**
	 * Set the access token issue time in milliseconds
	 * @param {*} state - current state
	 * @param {Int} rawTime - raw time in seconds
	 */
	SET_AUTH_ACCESS_TOKEN_ISSUE_TIME(state, msTime) {
		// convert to milliseconds for date math
		state.authAccessTokenIssueTime = msTime;
	},
	/**
	 * Set the the current access roles
	 * @param {*} state - current state
	 * @param {Array} accessRoles - access roles
	 */
	SET_ROLES(state, accessRoles) {
		state.roles = accessRoles;
	}
};

export const getters = {
	/**
	 * Get user by the given userId
	 * @param {*} state - current state
	 * @returns user object
	 */
	getUserById: (state) => (id) => {
		return state.teamMembers.find(member => member.userId === id);
	},
	/**
	 * Get the showAllTenantUsers flag
	 * @param {*} state - current state
	 * @returns showAllTenantUsers flag
	 */
	getShowAllTenantUsers: (state) => {
		return state.showAllTenantUsers;
	},
	/**
	 * Get the current team members
	 * @param {*} state - current state
	 * @returns team members list
	 */
	getTeamMembers: (state) => {
		return state.teamMembers;
	},
	/**
	 * Get team members error flag
	 * @param {*} state - current state
	 * @returns teamMembersError flag
	 */
	getTeamMembersError: (state) => {
		return state.teamMembersError;
	},
	/**
	 * Get the logged in user object
	 * @param {*} state - current state
	 * @returns logged in user object
	 */
	getLoggedInUser: (state) => {
		return state.loggedInUser;
	},
	/**
	 * Get the logged in user's tenantId
	 * @param {*} state - current state
	 * @returns logged in user's tenantId
	 */
	getTenantId: (state) => {
		return state.tenantId;
	},
	/**
	 * Get the logged in user's email
	 * @param {*} state - current state
	 * @returns string of the logged in user's email
	 */
	getLoggedInUserEmail: (state) => {
		return state.loggedInUserEmail;
	},
	/**
	 * Get the logged in user's name
	 * @param {*} state - current state
	 * @returns string of the logged in user's name
	 */
	getLoggedInUserName: (state) => {
		return state.loggedInUserName;
	},
	/**
	 * Get the logged in user's userId
	 * @param {*} state - current state
	 * @returns string of the logged in user's userId
	 */
	getLoggedInUserId: (state) => {
		return state.loggedInUserId;
	},
	/**
	 * Get the post login URL
	 * @param {*} state - current state
	 * @returns string of the post login URL
	 */
	getPostLoginUrl: (state) => {
		return state.postLoginUrl;
	},
	/**
	 * Get the list of available roles
	 * @param {*} state - current state
	 * @returns array of available roles
	 */
	getRoles: (state) => {
		return state.roles;
	},
	/**
	 * Get the list of clients
	 * @param {*} state - current state
	 * @returns array of client objects
	 */
	getClientList: (state) => {
		return state.clients;
	},
	/**
	 * Get the logged in userId token
	 * @param {*} state - current state
	 * @returns string of the loggedInUserIdToken
	 */
	getLoggedInUserIdToken: (state) => {
		return state.loggedInUserIdToken;
	},
	/**
	 * Get the active tenant object
	 * @param {*} state - current state
	 * @returns object
	 */
	getActiveTenant: (state) => {
		return state.activeTenant
	},
	/**
	 * Get the tenant list
	 * @param {*} state - current state
	 * @returns array of available tenants
	 */
	getTenantList: (state) => {
		return state.tenantList
	},
	/**
	 * Get the active tenant label
	 * @param {*} state - current state
	 * @returns string of active tenant label
	 */
	getActiveTenantLabel: (state) => {
		return state.activeTenantLabel;
	},
	/**
	 * Get the active clientId
	 * @param {*} state - current state
	 * @returns string of the active tenantId
	 */
	getActiveClientId: (state) => {
		return state.activeClientId;
	},
	/**
	 * Get the active tenantId
	 * @param {*} state - current state
	 * @returns string of the active tenantId
	 */
	getActiveTenantId: (state) => {
		return state.activeTenantId;
	},
	/**
	 * Get the access token
	 * @param {*} state - current state
	 * @returns string of the accessToken.jwtToken
	 */
	getAccessToken: (state) => {
		return state.accessToken.jwtToken;
	},
	/**
	 * Get the access token
	 * @param {*} state - current state
	 * @returns string of the accessToken.jwtToken
	 */
	getAuthAccessToken: (state) => {
		return state.authAccessToken;
	},
	/**
	 * Get the access token
	 * @param {*} state - current state
	 * @returns string of the accessToken.jwtToken
	 */
	getQFDAuthIdToken: (state) => {
		return "Bearer " + state.qfdAuthToken.IdToken;
	},
	/**
	* Get the access token
	* @param {*} state - current state
	* @returns string of the accessToken.jwtToken
	*/
	getQFDAuthToken: (state) => {
		return state.qfdAuthToken;
	},
	/**
	 * Get refresh token
	 * @param {*} state - current state
	 * @returns string of the refresh token
	 */
	getRefreshToken: (state) => {
		return state.refreshToken;
	},
	/**
	 * Get access token expiration
	 * @param {*} state - current state
	 * @returns int of the access token expiration (milliseconds)
	 */
	getAccessTokenExpiration: (state) => {
		return state.accessTokenExpiration;
	},
	/**
	 * Get access token issue time
	 * @param {*} state - current state
	 * @returns int of the access token issue time (milliseconds)
	 */
	getAccessTokenIssueTime: (state) => {
		return state.accessTokenIssueTime;
	},
	/**
	 * Get access token expiration
	 * @param {*} state - current state
	 * @returns int of the access token expiration (milliseconds)
	 */
	getAuthAccessTokenExpiration: (state) => {
		return state.authAccessTokenExpiration;
	},
	/**
	 * Get access token issue time
	 * @param {*} state - current state
	 * @returns int of the access token issue time (milliseconds)
	 */
	getAuthAccessTokenIssueTime: (state) => {
		return state.authAccessTokenIssueTime;
	},
	getIsExternalUser: (state) => {
		return state.roles.includes('QFD_ExternalUser');
	}
}

