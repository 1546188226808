<template>
  <div class="data-table">
    <div class="data-table-toolbar">
      <searchBar
        v-if="showInlineSearch"
        v-model="search"
        :searchPlaceholder="searchPlaceholder"
      />
      <v-btn
        v-if="hasFiltering"
        text
        @click="toggleFilter"
        class="filter-button"
        >Sort & Filter<v-icon
          :style="`transform: rotate(${showFilter ? 180 : 0}deg)`"
          >mdi-chevron-down</v-icon
        ></v-btn
      >
      <v-menu offset-y v-if="columnFiltering">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"> Fields </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="column in columnFiltering" :key="column.value" @click.stop="toggleColumn(column)">
            <v-list-item-action>
              <v-checkbox :input-value="column.display" color="primary"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ column.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div v-show="showFilter" class="filter-box">
      <div class="filter-header">
        <v-btn @click="applyFilter">Apply Sort & Filter</v-btn>
        <v-btn @click="resetFilter" text>Reset All</v-btn>
      </div>
      <div class="filter-content"><slot name="filter" /></div>
    </div>

    <slot name="filter-info" />

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      :item-key="itemKey"
      :search="search"
      :loading="loading"
      class=""
      hide-default-footer
      ref="dataTable"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-if="actionBtn" v-slot:item.action="{ item }">
        <v-btn text @click="actionBtnFunction(item)">
          <slot>View</slot>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <template v-if="deleteBtn" v-slot:item.delete="{ item }">
        <v-btn text @click="deleteBtnFunction(item)">
          <v-icon class="trash-icon">mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
    </v-data-table>

    <div class="pagination">
      <div class="range-string">{{ pageRangeString }}</div>
      <v-pagination
        v-model="page"
        total-visible="7"
        :length="
          length % itemsPerPage
            ? parseInt(length / itemsPerPage) + 1
            : parseInt(length / itemsPerPage)
        "
      >
      </v-pagination>
    </div>
  </div>
</template>

<script>
/**
 * @fileoverview Common component for a data table
 *
 * @author Sophia Frankel <sophia.frankel@quavo.com>
 */
import searchBar from "@/qux-common/components/search-bar.vue";

export default {
  name: "data-table",
  props: {
    columnFiltering: {
      type: Array,
    },
    /**
     * Headers for the data table
     */
    headers: {
      type: Array,
      required: true,
    },
    /**
     * Items included in the data table
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * item-key for data table
     */
    itemKey: {
      type: String,
      default: "id",
    },
    /**
     * Number of items per page
     * Default: 10
     */
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    /**
     * Table loading state
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Boolean flag for an action button
     */
    actionBtn: {
      type: Boolean,
      default: false,
    },
    /**
     * Function for the action button
     */
    actionBtnFunction: {
      type: Function,
      required: false,
    },
    /**
     * Boolean flag for an delete button
     */
    deleteBtn: {
      type: Boolean,
      default: false,
    },
    /**
     * Function for the delete button
     */
    deleteBtnFunction: {
      type: Function,
      required: false,
    },
    /**
     * Determines if the search box should be displayed
     */
    showInlineSearch: {
      type: Boolean,
      default: true,
    },
    /**
     * Placeholder string for the search bar
     * Default: "search by key data..."
     */
    searchPlaceholder: {
      type: String,
      default: "search by key data...",
    },
  },
  data() {
    return {
      page: 1, // page of data table
      search: "", // search bar text
      showFilter: false,
    };
  },
  components: {
    searchBar,
  },
  computed: {
    /**
     * Compute the length of the items in the data table based on the search bar
     * @returns the amount of visible items in the data table
     */
    length() {
      if (this.search.length) {
        return this.$refs.dataTable.$children[0].filteredItems.length;
      }
      return this.items.length;
    },
    /**
     * Compute the page range string
     * @returns string of the current page range
     */
    pageRangeString() {
      let totalItems = this.length;

      if (!totalItems) return "";
      let potentialUpperBound = this.itemsPerPage * this.page;
      let upperBound =
        potentialUpperBound > totalItems ? totalItems : potentialUpperBound;
      let lowerBound = potentialUpperBound - (this.itemsPerPage - 1);

      return `${lowerBound} - ${upperBound} out of ${totalItems}`;
    },
    hasFiltering() {
      return !!this.$slots.filter;
    },
  },
  methods: {
    /**
     * Resets the pagination value
     */
    resetPagination() {
      // reset to first page
      this.page = 1;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    applyFilter() {
      this.$emit("applyFilter");
    },
    resetFilter() {
      this.$emit("resetFilter");
    },
    toggleColumn(column) {
      this.$emit("toggleColumn", column)
    }
  },
  watch: {
    search() {
      // reset to first page when search terms are updated
      this.resetPagination();
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  .data-table-toolbar {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 2em;
    gap: 1em;
  }
  .filter-box {
    border-left: 8px solid $qvo-color-util-neutral-steel;
    .filter-header {
      padding: 0.75em;
      background-color: $qvo-color-util-neutral-steel;
    }
    .filter-content {
      padding: 0.75em;
    }
  }
  .trash-icon {
    color: $qvo-color-status-red;
  }
  &::v-deep {
    th.text-end {
      // Forces the sorting arrow to be on the right side of right-aligned columns
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row-reverse;
    }
  }
}
.v-list-item__title {
  font-size: 1.4rem;
}
.v-list-item__action {
  margin: 0;
}
</style>