<!--
	This component allows showing an icon with some text next to it.
-->
<template>
  <section class="labeled-icon">
    <v-icon>{{ icon }}</v-icon>
    <div>{{ label }}</div>
  </section>
</template>

<script>
export default {
  name: "labeledIcon",
  props: {
    icon: {
      // An icon from https://materialdesignicons.com/ prefixed with "mdi-"
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.labeled-icon {
    display: flex;
    align-items: center;
    .theme--light.v-icon {
        padding-right: 0.25em;
        color: $qvo-color-util-neutral-dark
    }
}
</style>