<template>
  <div
    id="token-timeout-warning"
    :class="{ 'token-timeout-visible': timeoutWarningActive }"
  >
    Due to inactivity, your session will end in
    <span class="token-timeout-time">{{ timeRemaining }}</span> seconds.
    <qvoButton @click="stayLoggedIn">Stay Logged In</qvoButton>
    <qvoButton outlined @click="logout">Logout</qvoButton>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

// There are two separate concerns being handled here:
//
// * Need to refresh the access token before it expires if the session is still valid
// * Need to log the user out if they've been idle for too long

const IDLE_TIMEOUT_MINS = 15;
const WARNING_TIME_SECS = 30;

export default {
  name: "tokenManager",
  components: {
    qvoButton,
  },
  data() {
    return {
      timeoutWarningActive: false,
      countdownInterval: null,
      authTimeout: null,
      timeRemaining: null,
      warningTimer: null,
      gettingNewSession: false,
    };
  },
  computed: {
    ...mapGetters({
      accessToken: "getAccessToken",
      accessTokenExp: "getAccessTokenExpiration",
      accessTokenIss: "getAccessTokenIssueTime",
    }),
  },
  watch: {
    warningTimer() {
      // again, just making sure the dev tools update instantly
    },
    timeoutWarningActive() {
      if (this.timeoutWarningActive) {
        this.timeRemaining = WARNING_TIME_SECS;
        this.countdownInterval = setInterval(this.countdownTimeRemaining, 1000);
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.activeUpdate);
    window.addEventListener("click", this.activeUpdate);
    if (!this.gettingNewSession) {
      this.createAccessTokenTimer();
    }
    this.createIdleTimer();
  },
  methods: {
    createAccessTokenTimer() {
      // if we get here and somehow the expiration has already passed, or there's no token, logout immediately
      if (!this.accessToken || this.accessTokenExp - Date.now() < 0) {
        console.error("No valid token found! Ending session");
        this.logout();
        // make sure we don't continue processing
        return null;
      }

      // set a timer within 30 seconds of expiration
      const timeToRefreshSession = this.accessTokenExp - 30000 - Date.now();
      this.sessionRefreshTimer =
        timeToRefreshSession < 1000 ? 1000 : timeToRefreshSession;
      console.info(
        "Access token expires in " +
          ((timeToRefreshSession / 1000 + 30) / 60).toFixed(2) +
          " minutes"
      );

      this.authTimeout = setTimeout(() => {
          this.refreshSession();
      }, this.sessionRefreshTimer);
    },
    createIdleTimer() {
      this.removeWarningTimer();

      this.warningTimer = setTimeout(() => {
        this.timeoutWarningActive = true;
      }, IDLE_TIMEOUT_MINS * 60 * 1000);
    },
    activeUpdate() {
      this.createIdleTimer();
    },
    removeAuthTimoutTimer() {
      clearTimeout(this.authTimeout);
      this.authTimeout = null;
    },
    removeCountdownInterval() {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    },
    removeWarningTimer() {
      clearTimeout(this.warningTimer);
      this.warningTimer = null;
    },
    cleanupTimers() {
      this.timeRemaining = null;
      this.removeAuthTimoutTimer();
      this.removeCountdownInterval();
      this.removeWarningTimer();
    },
    async refreshSession() {
      let oldToken = this.accessToken;
      this.gettingNewSession = true; // prevents new timers until we're ready

      this.removeAuthTimoutTimer();
      await this.$store.dispatch("refreshSession");

      if (this.accessToken === oldToken) {
        console.warn("Failed to get a new token during refresh.");
      }

      await this.$store.dispatch("getRefreshToken");
      await this.$store.dispatch("getLoggedInUserInfo");
      await this.$store.dispatch("getTenantList");
      this.createAccessTokenTimer();
      this.gettingNewSession = false;
    },
    countdownTimeRemaining() {
      if (this.timeRemaining !== null && this.timeoutWarningActive) {
        if (this.timeRemaining < 1) {
          console.info("Forced logout due to session expiration");
          this.logout();
        }
        this.timeRemaining--;
      } else {
        console.warn("Attempted to countdown on null timer.");
      }
    },
    stayLoggedIn() {
      this.timeoutWarningActive = false;
      this.removeCountdownInterval();
      this.activeUpdate();
    },
    logout() {
      this.cleanupTimers();
      this.$store.dispatch("logout");
    },
  },
  beforeDestroy() {
    this.cleanupTimers();
  },
};
</script>

<style lang="scss" scoped>
#token-timeout-warning {
  font-size: 1.5em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em;
  text-align: center;
  background-color: #bbbdc5;
  border-top: 1em solid #ae2727;
  transform: translate(0, 100%);
  transition: translate 1s ease-in;
  z-index: 1000;
  .v-btn {
    margin-left: 2em;
  }

  &.token-timeout-visible {
    transform: translate(0, 0);
  }
}
</style>
