<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    class="button"
    elevation="0"
    :class="warning && 'warning'"
  >
    <v-icon
      v-if="leftIcon"
      class="left-icon"
      :style="`transform: rotate(${flipLeftIcon ? 180 : 0}deg)`"
      >{{ leftIcon }}</v-icon
    >
    <span class="label"><slot /></span>
    <v-icon
      v-if="rightIcon"
      class="right-icon"
      :style="`transform: rotate(${flipRightIcon ? 180 : 0}deg)`"
      >{{ rightIcon }}</v-icon
    >
  </v-btn>
</template>

<script>
/**
 * @fileoverview Common component for buttons
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
export default {
  name: "Button",
  props: {
    leftIcon: {
      type: String,
    },
    rightIcon: {
      type: String,
    },
    flipLeftIcon: {
      type: Boolean,
      default: false,
    },
    flipRightIcon: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round) {
  background-color: $qvo-color-blue-400;
  border-radius: 8px;
  transition-property: background-color, border;
  border-width: 0;
  &:before {
    background-color: unset;
  }
  .label,
  .v-icon {
    color: $qvo-color-grey-000;
    &::after {
      background: none;
    }
  }
  &.v-size--default {
    height: 42px;
  }
  &.v-size--default,
  &.v-size--large {
    padding: 9px 16px;
  }
  &.v-btn--has-bg {
    background-color: $qvo-color-blue-400;
  }
  &.v-btn--outlined {
    background-color: $qvo-color-grey-000;
    border: solid $qvo-color-blue-400;
    .label,
    .v-icon {
      color: $qvo-color-blue-400;
    }
    &:hover {
      background-color: $qvo-color-blue-200;
    }
    &.v-btn--disabled {
      border: solid $qvo-color-grey-200;
    }
  }
  &.v-btn--text {
    background-color: transparent;
    .label,
    .v-icon {
      color: $qvo-color-blue-400;
    }
    &:hover {
      background-color: $qvo-color-blue-200;
    }
  }
  &.warning {
    background-color: $qvo-color-red-300;
    border: solid $qvo-color-red-300;
    &:hover {
      background-color: $qvo-color-red-400;
      border: solid transparent;
    }
    &.v-btn--outlined {
      background-color: $qvo-color-grey-000;
      .label,
      .v-icon {
        color: $qvo-color-red-400;
      }
      &:hover {
        background-color: #EFD4D4;
        border: solid $qvo-color-red-400;
      }
    }
  }
  &:hover {
    background-color: $qvo-color-blue-700;
  }
  &.v-btn--disabled {
    background-color: $qvo-color-grey-200 !important; // Vuetify uses !important here to set the disabled background color 🤦
    .label,
    .v-icon {
      color: $qvo-color-grey-000 !important; // Vuetify uses !important here to set the disabled background color 🤦
    }
  }
  &.v-size--small {
    height: 36px;
    padding: 6px 16px;
  }
  &:hover:before {
    opacity: 0;
  }
  .left-icon {
    margin-right: 12px;
  }
  .right-icon {
    margin-left: 12px;
  }
}

// These apply specifically to the FAB button
.v-btn.v-btn--round {
  background-color: $qvo-color-blue-400;
  transition-property: background-color, border;
  border: solid $qvo-color-blue-400;
  height: 60px;
  width: 60px;
  .label {
    color: $qvo-color-grey-000;
  }
  &:hover {
    background-color: $qvo-color-blue-700;
    border: solid $qvo-color-blue-700;
  }
  &.v-btn--outlined {
    background-color: $qvo-color-grey-000;
    .label {
      color: $qvo-color-blue-400;
    }
    &:hover {
      background-color: $qvo-color-blue-200;
      border: solid $qvo-color-blue-400;
    }
  }
  &.v-btn--disabled {
    color: $qvo-color-grey-000 !important; // Vuetify uses !important here to set the disabled background color 🤦
    background-color: $qvo-color-grey-200 !important; // Vuetify uses !important here to set the disabled background color 🤦
    border: solid $qvo-color-grey-200;
    &::v-deep {
      .v-icon {
        color: $qvo-color-grey-000 !important; // Vuetify uses !important here to set the disabled background color 🤦
      }
    }
  }
  &.v-size--small {
    height: 42px;
    width: 42px;
  }
  &.v-size--large {
    height: 72px;
    width: 72px;
  }
}
</style>