export const state = {
    search: '', // search term
    daysToLookBack: 30,	// how many days to look back for search
    searchType: 'claimId',	// type of search being performed
};

/*
    Actions hook up to the interface, but they do not touch the store data directly.
    They can be rather complicated and will generally lean on one or more mutations.
    Actions are also the communication layer with external sources (API).

    Actions come with access to four contexts ({ state, getters, commit, dispatch })
        state = direct data access... avoid use
        getters = indirect data access... this is good
        commit = call a mutation
        dispatch = for us, dispatch will almost certainly be an axios api call
*/
export const actions = {
    /**
     * Set the search term
     * @param {*} contexts - commit 
     * @param {String} term - search term
     */
    async setSearch({ commit }, term) {
        commit("SET_SEARCH", term);
    },
    /**
     * Set the search days
     * @param {*} contexts - commit 
     * @param {String} days - search days to look back
     */
    async setSearchDays({ commit }, days) {
        commit("SET_SEARCH_DAYS", days);
    },
    /**
     * Set the search type
     * @param {*} contexts - commit 
     * @param {Number} term - search type
     */
    async setSearchType({ commit }, type) {
        commit("SET_SEARCH_TYPE", type);
    },
    /**
     * Reset the search
     * @param {*} contexts - commit 
     */
    async resetSearch({ commit }) {
        commit("SET_SEARCH_TYPE", 'claimId');
        commit("SET_SEARCH", '');
        commit("SET_SEARCH_DAYS", 30);
    },
};

/*
    Mutations are methods that act on the state data directly.
    They should be incredibly basic and perform a single task.

    Mutations always take in the current 'state' and a payload.
*/
export const mutations = {
    /**
     * Set the search term
     * @param {*} state - current state
     * @param {String} term - search term
     */
    SET_SEARCH(state, term) {
        state.search = term;
    },
    /**
     * Set the search type
     * @param {*} state - current state
     * @param {Number} claim - search days to look back
     */
    SET_SEARCH_DAYS(state, days) {
        state.daysToLookBack = days;
    },
    /**
     * Set the search type
     * @param {*} state - current state
     * @param {String} claim - search type
     */
    SET_SEARCH_TYPE(state, type) {
        state.searchType = type;
    },
};