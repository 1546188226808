
/**
 * CustomerService.js contains all of the service functions used for customers.
 *
 * @endpoints /customer/
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */
 import store from '@/store/store.js';
 import API from '@/services/QFDAPIService';
 
 export default {
     name: 'CustomerService',

     /**
      * Retrieve customer data
      * @param {String} CustomerId - id to load
      * @returns an object containing claim data
      */
     retrieveCustomer(CustomerId) {
         return API.call('/customer/retrieve', {
            CustomerId,
         }).catch(error => {
			var message = "";
			var defaultMessage = error.message + '. Please refresh the page or try again later.';
			if (error.response) {
				if(error.response.data.StatusList){
					message = "Error retrieving customer. ";
					error.response.data.StatusList.forEach(status => message += `${status.Message} `);
				}
			}
			store.dispatch("notifications/addNotification", {
				status: 'error',
				message: message || defaultMessage,
			});
         });
     },
 };