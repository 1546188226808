<!--
	This component defines the inner content of an individual tab within a <tab-group>.
	
	Since this is entirely internal, it can be expanded upon.
		Look at <v-tab-item> for ideas to borrow from when/if necessary.
-->
<template>
	<section v-if="isActive">
		<slot></slot>
	</section>
</template>

<script>
	export default {
		name: "tabItem",
		props: {
			title: {
				// what should the tab button say?
				type: String,
				required: true
			},
			selected: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				isActive: false,
			};
		},
		computed: {
			titleID() {
				return this.title.toLowerCase().replace(/ /g, '-');
			},
			href() {
				return '#' + this.titleID;
			}
		},
		mounted() {
			this.isActive = this.selected;
		}
	};
</script>